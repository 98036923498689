import React, { useState } from "react";
import "../Style/ContactUs.scss";
import "../Style/AboutUs/common.scss";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
// import TextareaAutosize from "@mui/material/TextareaAutosize";

import VisibilitySensor from "react-visibility-sensor";
import $ from "jquery";

const DropDown = ({ label, item, name, changeItem, options }) => (
  <>
    <Box sx={{ minWidth: "100%" }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          sx={{ height: "50px" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item}
          label={label}
          margin="dense"
          name={name}
          onChange={changeItem}
        >
          {options?.map((option) => (
            <MenuItem value={option.value}>{option.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  </>
);

function ContactUs() {
  const [user, setUser] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
    role: "",
    interest: "",
    country: "",
  });

  const [checked, setChecked] = useState(false);

  const toggleCheck = () => {
    checked ? setChecked(false) : setChecked(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const register = () => {
    const { name, email, message, role, interest, phone } = user;
    if (name && email && message && role && interest && phone) {
      axios
        .post("https://unesco-backend.vercel.app/contact", user)
        .then((res) => console.log(res));
    } else {
      alert("Fill all The Informations");
    }

    setUser({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <div className="contactus-main">
      <div className="green-box"></div>
      <div className="contactus">
        <div className="grid-container">
          <div className="mycontent item1">
            <h1>Contact Us</h1>
            <hr />
          </div>
          <div className="item2">
            <form className="cntform">
              <span>Leave us a message</span>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                name="name"
                onChange={handleChange}
                size="small"
                placeholder="Full Name"
                value={user.name}
                margin="dense"
              />
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                onChange={handleChange}
                size="small"
                placeholder="Email Address"
                margin="dense"
                value={user.email}
              />
              <TextField
                fullWidth
                id="outlined-basic"
                label="Phone Number"
                variant="outlined"
                name="phone"
                onChange={handleChange}
                size="small"
                placeholder="Phone Number (Prefferably Whatsapp)"
                margin="dense"
                value={user.phone}
              />
              <TextField
                fullWidth
                id="outlined-basic"
                label="Country"
                variant="outlined"
                name="country"
                onChange={handleChange}
                size="small"
                placeholder="Country"
                margin="dense"
                value={user.country}
              />
              <DropDown
                label={"Category"}
                item={user.role}
                changeItem={handleChange}
                options={[
                  { value: "Teacher", label: "Teacher" },
                  { value: "Student", label: "Student" },
                  {
                    value: "University Representative",
                    label: "University Representative",
                  },
                  { value: "Other", label: "Other" },
                ]}
                name="role"
              />
              <DropDown
                label={"Interested In"}
                item={user.interest}
                changeItem={handleChange}
                options={[
                  { value: "Courses", label: "Courses" },
                  { value: "Research", label: "Research" },
                  { value: "Conferences", label: "Conferences" },
                  { value: "Membership", label: "Memberships" },
                  { value: "Other", label: "Other" },
                ]}
                name="interest"
              />
              <TextField
                fullWidth
                id="outlined-basic"
                label="Your Messsage"
                variant="outlined"
                name="message"
                onChange={handleChange}
                size="small"
                placeholder="Message"
                value={user.message}
                margin="dense"
                multiline
              />

              <p className="form-text">
                We will use this information to connect you with right person to
                answer your question and may send related content.By checking
                the box,you allow us to store and process the information
                submitted above for this purpose. You may unsubscribe from these
                communications at any time. For more information please review
                our Privacy Policy.
              </p>
              <p className="form-text">
                <input type="checkbox" name="check" onChange={toggleCheck} /> I
                want to receive other communications from Department of
                Education International Chair in Bioethics & WMA Co-Operating
                Centre Melbourne, Australia
              </p>
              <button
                type="button"
                onClick={register}
                className="btn-send"
                style={{
                  margin: 0,
                  background: "#01471B",
                  width: "100%",
                  borderRadius: 0,
                  padding: "2%",
                  color: "#ffffff",
                  fontSize: "1.2rem",
                }}
              >
                Send
              </button>
            </form>
          </div>
          <div className="line item3"></div>
          <div className="item4 map-flex">
            <iframe
              title="Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.8191639541014!2d145.21273954031173!3d-37.98134933001378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6141a9eeff9f3%3A0x45518a1f1003d301!2s71%20Cleeland%20St%2C%20Dandenong%20VIC%203175%2C%20Australia!5e0!3m2!1sen!2sin!4v1643950821064!5m2!1sen!2sin"
              width="90%"
              height="90%"
              allowFullScreen={false}
              loading="lazy"
            ></iframe>
            <p className="map-text">
              71 Cleeland Street , Melbourne
              <br />
              Victoria 3175, AU
              <br />
              unescochairbioethics.education@gmail.com
            </p>
          </div>
        </div>
      </div>
      <div className="green-box"></div>
    </div>
  );
}

export default ContactUs;
