import React from "react";
import "../.././Style/LandingPage/Facilities.scss";

function Facilities() {
  return (
    <div className=" facilities container">
      <div className="row">
        <div className=" col-lg-2 fLogo">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1643995040/lvc_lmmdrx.png"
            alt=""
          />
          <p>Live Virtual Classrooms</p>
        </div>

        <div className=" col-lg-2 fLogo">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1643995040/scholarlyPubl_iuishg.png"
            alt=""
          />
          <p>Scholarly Publications</p>
        </div>

        <div className=" col-lg-2 fLogo">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1643995041/research_e0nngw.png"
            alt=""
          />
          <p>High Quality Research</p>
        </div>

        <div className=" col-lg-2 fLogo">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1643995041/network_1_eyfeeo.png"
            alt=""
          />
          <p>World Wide Community Of Healthcare Professionals </p>
        </div>

        <div className=" col-lg-2 fLogo">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1643995040/online-conference_wrtchv.png"
            alt=""
          />
          <p>
            International Conferences<span className="and"> & </span>Webinars{" "}
          </p>
        </div>
        <div className=" col-lg-2 fLogo">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1643995041/certificate_2_azo2y5.png"
            alt=""
          />
          <p>
            Diploma<span className="and"> & </span>Certificate Courses
          </p>
        </div>
      </div>
    </div>
  );
}

export default Facilities;
