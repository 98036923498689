import React from "react";
import "../.././Style/AboutUs/ucbsa.scss";
import OurTeam from "./OurTeam";
import ReginolDirector from "./ReginolDirector";
import Vision from "./Vision/Vision";
import WhyJoin from "./whyjoin/WhyJoin";
import ContactUs from "./ContactUs/ContactUs";
import { Link } from "react-router-dom";

function UCBSA() {
  return (
    <div className="ucbsamain">
      <div className="ucbsatop">
        <div className="ucbsaleft">
          <h3>INTERNATIONAL CHAIR IN BIOETHICS</h3>
          <h3>STUDENT ASSOCIATION INC.</h3>
          <h6>Reg No. A0114595P Victoria, Australia</h6>
        </div>

        <div className="ucbsaright">
          <div className="ucbsabuttons">
            <a
              href="https://res.cloudinary.com/unesco-admin/image/upload/v1671878112/booklet_h2evao.pdf"
              target="_blank"
            >
              <button className="ucbsabutton button-74">
                Information Booklet
              </button>
            </a>
            <Link to="/com?international">
              <button className="ucbsabutton button-74"> Our Structure </button>
            </Link>
            {/* <Link to="/stuUnits">
              <button className="ucbsabutton button-74"> Student Units </button>
            </Link> */}
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=secretary.ucbsa-intl@unesco-bioethics-chair.org"
              target="_blank"
            >
              <button className="ucbsabutton button-74"> Become Member </button>
            </a>
          </div>
        </div>
      </div>

      <div className="ucbsabottom">
        <div className="social">
          <p>Follow Us</p>
          <a
            href="https://instagram.com/ucbsa_intl?utm_medium=copy_link"
            target="_blank"
          >
            <i class="fa fa-instagram"></i>
          </a>
          {/* <a href="https://twitter.com/ucbsa_intl?s=11" target="_blank"><i class="fa fa-twitter"></i></a> */}
          <a
            href="https://www.facebook.com/UCBSA-Intl-101581158879569"
            target="_blank"
          >
            <i class="fa fa-facebook"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/international-chair-in-bioethics-student-association-icbsa/"
            target="_blank"
          >
            <i class="fa fa-linkedin"></i>
          </a>
        </div>

        <div className="bottomcontent">
          <p>
            International Chair in Bioethics Students’ Association is a global
            network of students and youth committed towards one shared goal of
            increasing interest and respect to ethics and values involved in
            health care delivery and raising awareness for competing interests
            working under the aegis of the Department of Education (former
            UNESCO Chair in Bioethics).
          </p>
        </div>

        <img
          src="https://res.cloudinary.com/unesco-admin/image/upload/v1671878449/ICBSA_LOGO_FINAL_sq2ecl.png"
          alt=""
        />
      </div>

      <WhyJoin />
      {/* <Vision/> */}
      <OurTeam />
      <ReginolDirector />
      <ContactUs />
    </div>
  );
}

export default UCBSA;
