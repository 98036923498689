import React from 'react'
import './ContactUs.scss'
import { MdPhone, MdHome, MdWeb } from 'react-icons/md'


function ContactUs() {
    return (
        <div id="UCBSA-contact">
            {/* <h1>Get  Involved</h1> */}
            <div className='row'>
                {/* <div className='col-lg-6 contact-img'>
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1649701457/2937927-removebg-preview_ikl3m5.png"></img>
                </div> */}
                <div className='col-lg-6 '>
                    <ol>
                        <li>
                            <h3>Contact Us</h3>
                            <h5>+964 750 710 7607</h5>
                            <h5>secretary.ucbsa-intl@unesco-bioethics-chair.org</h5>
                        </li>
                        <li>
                            <h3>Address</h3>
                            <h5>71 Cleeland Street Melbourne, 3175, Australia</h5>
                        </li>
                        <li>
                            <h3>Website</h3>
                            <h5>www.edu.unesco-bioethics-chair.org</h5>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default ContactUs

// const ContactComponent = ({icon, heading,desc,descd})=>(
//     <div className='contact__item'>
//         <div className='contact__item-icon'>
//             <div>{icon}</div>
//         </div>
//         <div className='contact__item-text'>
//             <div className='contact__item-text-heading'>{heading}</div>
//             <div className='contact__item-text-desc'>{desc}</div>
//             <div className='contact__item-text-desc'>{descd}</div>
//         </div>
//     </div>
// )

// const ContactUs = () => {
//   return (
//     <div className='contact__container'>
//         <div className="contact__container__heading"><h4>Get Involved</h4></div>
//         <div className='contact__container-flex'>
//             <ContactComponent icon={<MdPhone size={48}/>} heading="Contact Us" desc="+91-9810525074"  descd="secretary.ucbsa-intl@unesco-bioethics-chair.org"/>
//             <ContactComponent icon={<MdHome size={48}/>} heading="Address" desc="71 Cleeland Street Melbourne, 3175, Australia"/>
//             <ContactComponent icon={<MdWeb size={48}/>} heading="Website" desc="www.edu.unesco-bioethics-chair.org"/>
//         </div>
//     </div>
//   )
// }

