import React from "react";
// import sImg from ".././Images/sampleImg.png";
import "../.././Style/AboutUs/common.scss";
import "../.././Style/AboutUs/GoverningBody.scss";

function Faculty() {
  return (
    <div className="Governing-body aboutHead">
      <h1>Faculty And Trainers</h1>
      <div className="section1">
        <div id="members" className="row">
          <div id="members1" className="col-sm-6 col-md-6 col-lg-6">
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1643981415/Dr_Russell_DSouza_-_Copy_fabqtz.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Russell Dsouza</h2>
                <h3>Melbourne, Australia</h3>
              </div>
            </div>

            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1643981961/Prof_Gerhard_Fortwengel_e8nrii.png"
                alt=""
              ></img>
              <div>
                <h2>Prof. Gerhard Fortwengel</h2>
                <h3>Hannover, Germany</h3>
              </div>
            </div>

            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1643982008/Dr_Derek_Dsouza_audgyt.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Derek Dsouza</h2>
                <h3>Pune, India</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644002106/Dr_Stacy_Gallin_USA_e9uxsx.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Stacy Gallin</h2>
                <h3>New Jersey, USA</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644002200/Vivienne_Harpwood_-_Chair_qxtw1m.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Vivienne Harpwood</h2>
                <h3>United Kingdom</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644002274/princy-louis-palatty_-_Copy_cxwhsd.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Princy Palatty</h2>
                <h3>Kochi, India</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644002331/Prof_Daniella_Kediar_rvs2gr.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Daniella Keider</h2>
                <h3>Haifa, Israel</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644002427/Prof_Shabbir_Amanullah_London_Canada_yfsew0.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Shabbir Amanullah</h2>
                <h3>Ontario, Canada</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644004337/Vojin_Rakic_h8f0n6.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Rakic Vojic</h2>
                <h3>Belgrade, Serbia</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644002595/Prof._Rui_Nunes_szpyk4.png"
                alt=""
              ></img>
              <div>
                <h2>Prof. Rui Nunes</h2>
                <h3>Porto, Portugal</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1652623909/Praveen_Arora_gyn4lg.png"
                alt=""
              ></img>
              <div>
                <h2>Prof. Praveen Arora</h2>
                <h3>India</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1703666940/tlgvcygdwum1nliqarsf.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Ved Prakash Mishra</h2>
                <h3>Nagpur, India</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644004244/Prof_Shai_Linn_Israel_svjibl.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Shai Linn</h2>
                <h3>Haifa, Israel</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644005141/Dr._Ani_Grace_dayo8a.png"
                alt=""
              ></img>
              <div>
                <h2>Prof. Ani Grace Kalaimathi</h2>
                <h3>Chennai, India</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644147862/Dr_Avinash_Desousa_depylj.webp"
                alt=""
              ></img>
              <div>
                <h2>Prof. Avinash Desousa</h2>
                <h3>Sion Hospital, Mumbai, India</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644148079/Dr_Mike_Redinger_bwuexs.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Mike Redinger</h2>
                <h3>University of Western Michigan, Kalamazoo, USA</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644147591/DR_Himanshu_Pandya_zboks9.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Himanshu Pandya</h2>
                <h3>Dean of PS Medical College, Gujarat, India</h3>
              </div>
            </div>

            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1652887700/Kathleen_bhvoiz.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Kathleen DiGangi Condon</h2>
                <h3>USA</h3>
              </div>
            </div>

            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1652623895/raya_u0uqqj.jpg"
                alt=""
              ></img>
              <div>
                <h2>Ms. Raya Gurevich-Gal</h2>
                <h3>Israel</h3>
              </div>
            </div>

            
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1667759391/Dr_Gaurav_Misha_India_1_kkr82j.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Gaurav Mishra</h2>
                <h3>Pro- Vice Chancellor, Datta Meghe Institute of Medical Sciences, Wardha (India)</h3>
              </div>
            </div>
          </div>

          <div id="members2" className="col-sm-6 col-md-6 col-lg-6">
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1643981927/Professor_Mary_Mathew_wbgi02.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Mary Mathew</h2>
                <h3>Manipal, India</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644001918/Baroness_Finlay_2018_002_jqznhp.jpg"
                alt=""
              ></img>
              <div>
                <h2>Baroness Prof Ilora Finlay</h2>
                <h3>Cardiff, United Kingdom</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1643982033/Prof_Joseph_Thornton_Uni_of_Florida_USA_o5apkn.png"
                alt=""
              ></img>
              <div>
                <h2>Prof. Joseph Thornton</h2>
                <h3>Florida, USA</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644145600/Dr_K_Gireesh_auhsfv.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. K Gireesh</h2>
                <h3>Chennai, India</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644145618/Dr._Judie_wqxiwm.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Judie Arullapan</h2>
                <h3>Head of Nursing, University of Muscat, Oman</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644145683/Dr_Anu_jmvvzl.webp"
                alt=""
              ></img>
              <div>
                <h2>Prof. Anu Sharma</h2>
                <h3>Ludhiana, India</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644148755/Dr_Tara_nou4uw.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Tara Shallal</h2>
                <h3>Kurdish, Iraq</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644145723/Dr._Srikumar_Vasudevan_wanzwu.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Srikumar Vasudevan</h2>
                <h3>Kochi, India</h3>
              </div>
            </div>
            {/* <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644145750/dr_Shanthi_G_kff2wt.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Shanti Van Zeebroeck</h2>
                <h3>Brusslles, Belgium</h3>
              </div>
            </div> */}
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644145767/Prof_J_S_Bamrah_UK_nknnai.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. J S Bamrah</h2>
                <h3>Manchester, UK</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644145788/Dr_Piyal_Sen_es5ox7.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Piyal Sen</h2>
                <h3>London, United Kingdom</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644145810/Dr_Srikanth_N_u2hggr.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Srikanth Nimmagadda</h2>
                <h3>United Kingdom</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644147301/Prof_Bryan_cxpgij.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Bryan Vernon</h2>
                <h3>Newcastle, UK</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644147340/Prof._bruce-e-gelb_n0cpbk.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Bruce Gelb</h2>
                <h3>New York, USA</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644147385/Dr_Raju_Lakshmana_Melbourne_v5edk1.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Raju Lakshmanan</h2>
                <h3>Melbourne</h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644147540/Augustine_Pamplany_zen6ox.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Augustine Pamplany</h2>
                <h3>
                  Director- Institute of Science<span className="and"> & </span>
                  Religion, Aluva, Kerala, India
                </h3>
              </div>
            </div>
            {/* <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1644147567/Prof._Desmond_vzrgoo.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Desmond Cahill</h2>
                <h3>RMIT University Melbourne, Australia</h3>
              </div>
            </div> */}
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1652549685/Prof.Selva_Titus_littcl.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Selva Titus Chacko</h2>
                <h3>UAE
                </h3>
              </div>
            </div>
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1652623895/miriam_ng9iyc.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Miriam Wagoro</h2>
                <h3>Kenya
                </h3>
              </div>
            </div>

            {/*  */}
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1667759167/Dr._Sreenivas_Passport_f4hbbi.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Srinivas</h2>
                <h3>Director and Professor, Department of Forensic Medicine, MAMC, New Delhi (India)
                </h3>
              </div>
            </div>

            
            <div className="greenBox">
              <img
                src="https://res.cloudinary.com/unesco-admin/image/upload/v1667759194/Dr_Animesh_Jain_zjvoso.jpg"
                alt=""
              ></img>
              <div>
                <h2>Prof. Animesh Jain</h2>
                <h3>Professor and Head of the Department, Department of Community Medicine, KMC Mangalore (India)
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Faculty;
