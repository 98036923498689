import React from "react";
import { useState, useEffect } from "react";
import ".././Style/StudentUnits.scss";
import LoadingScreen from "react-loading-screen";
import axios from "axios";

function StudentUnits() {
  const [studentUnits, setStudentUnits] = useState([]);
  const [loading, setLoading] = useState(true);

  const getstudentUnits = async () => {
    await axios
      .get("https://unesco-backend.vercel.app/studentUnit")
      .then((res) => {
        const data = res.data;
        // data.reverse()
        setStudentUnits(data);
      });
    setLoading(false);
  };

  useEffect(() => {
    getstudentUnits();
  }, []);

  return (
    <div id="committees" className="aboutHead">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Getting Student Units..."
      ></LoadingScreen>

      <h1>Student Units</h1>
      <table className="table table-bordered align-middle table-responsive">
        <thead className="table-light">
          <tr>
            <th className="sno">Sno</th>
            <th>Unit</th>
            <th>University/Country</th>
            <th>Unit Head</th>
            <th>Report</th>
          </tr>
        </thead>
        {studentUnits.length > 0
          ? studentUnits.map((value, index) => {
              return (
                <tbody>
                  <tr>
                    <td>{value.sno}</td>
                    <td>{value.unit}</td>
                    <td>{value.university}</td>
                    <td>{value.unitHead}</td>
                    <td>
                      {value.report ? (
                        <a href={value.report} target="_blank">
                          <button class="button-74" role="button">
                            View Report
                          </button>
                        </a>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              );
            })
          : null}
      </table>
    </div>
  );
}

export default StudentUnits;
