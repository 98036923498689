import React from "react";
import "./Vision.scss";

const VisionCompponent = ({ heading, points, flexState, imgLink, imgWidth, link }) => {
  return (
    <div
      className={`vision__container ${!flexState ? 'vision__container__reverse' : ''}`}
    >
      <div className="vision-img-container" >
        <img
          src={imgLink}

          className={`vision-img-container__img ${flexState ? 'vision-img-container__img__largeImg' : ''}`}
          alt=""
        />
      </div>
      <div className="vision__container__text"   >

        <h4 className="vision__container__text__heading">{heading}</h4>
        <div className="vision__container__text__content">
          <ul>
            {points.map((point) => (
              <li>{point}</li>
            ))} {link ? <a href={link} target="_blank">ICBSA Module</a> : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

const Vision = () => {
  return (
    <>
      <VisionCompponent
        heading="Objectives"
        points={[
          "Teaching ethical principles and values: One of the primary objectives of bioethics and medical education is to teach undergraduate and postgraduate students and bioethics, teaching faculty members the principles and values that underpin ethical medical practice. This includes teaching students about the importance of patient autonomy, informed consent, confidentiality, and other key ethical concepts.",
          "Developing critical thinking skills: Another objective is to help students develop the critical thinking skills necessary to analyse and evaluate complex ethical issues. This includes teaching them how to identify and weigh competing ethical principles, evaluate arguments, and apply ethical frameworks to real-world situations.",
          "Promoting professionalism: Bioethics and medical education also aim to promote professionalism among medical students and practitioners. This includes teaching students about the importance of maintaining high ethical standards, treating patients with respect and empathy, and working collaboratively with colleagues.",
          "Fostering cultural competence: In an increasingly diverse and globalized world, bioethics and medical education also aim to foster cultural competence among students and practitioners. This includes teaching them how to navigate cultural differences, understand the social and cultural factors that influence health and healthcare, and provide culturally responsive care.",
          "Advancing research ethics: Bioethics and medical education also play a critical role in advancing research ethics. This includes teaching students about the ethical considerations involved in conducting research with human subjects, ensuring informed consent and protection of vulnerable populations, and maintaining the integrity of scientific research.          Supporting medical education and bioethics education research and publications. The fostering of the Global Networks International Journal Global Bioethics Enquiry."
        ]}
        flexState
        // link="https://res.cloudinary.com/unesco-admin/image/upload/v1685360852/ICBSA_II_5__compressed_ew3dtk.pdf"
        imgLink='https://res.cloudinary.com/unesco-admin/image/upload/v1686470456/image-removebg-preview_1_mxk8bb.png'
        imgWidth='100%'

      />
      <VisionCompponent
        heading="Collaborations"
        points={[
          "Medical schools and universities: These institutions are responsible for training the next generation of healthcare professionals and can significantly promote the integration of bioethics and medical education.",
          "Associations working in the medical and healthcare education field: Working in the medical and healthcare education sphere will have many areas where they can collaborate with the GNBMHPE to ensure the spread of integrated bioethics healthcare education across the globe.",
          "Hospitals and healthcare provider networks: These institutions are where healthcare professionals practice and can benefit from education and training in bioethics to improve patient care and outcomes.",
          "Bioethics centres and organizations: These institutions focus on research, education, and advocacy related to bioethics and can provide valuable expertise and resources for the Global Network of Bioethics, Medical and Health Professions Education.",
          "International health organizations: The role of international health organisations is to work together to improve global health. They would like to collaborate as they are strongly interested in promoting bioethics and medical education as part of their efforts.",
          "Government agencies: These institutions are responsible for laying down healthcare policies and regulations and regulating medical education. They can play a vital responsibility in promoting bioethics and medical education as part of their efforts to improve public health.",
          "Non-governmental organizations (NGOs): These institutions often work in areas related to health, human rights, and social justice and can be valuable partners in promoting bioethics and medical education to improve health outcomes for vulnerable populations."
        ]}
        imgLink='https://res.cloudinary.com/unesco-admin/image/upload/v1686470594/image-removebg-preview_2_seycij.png'
        imgWidth='100%'
      />
    </>
  );
};

export default Vision;
