import React from "react";
import "../.././Style/LandingPage/PrincipalMessage.scss";
import { Link } from "react-router-dom";

function PrincipalMessage() {
  return (
    <div className="maincont">
      <div className="principal">
        <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1643981415/Dr_Russell_DSouza_-_Copy_fabqtz.jpg" alt="principal" />
        <div className="name">
          <p>Prof. RUSSELL DSOUZA</p>
          <p>Chair<span className="and"> & </span>Head. Department of Education</p>
          <p>International Chair in Bioethics &</p>
          <p>WMA Co-Operating Centre </p>
          <p>University of Porto, Melbourne, Australia</p>
        </div>
      </div>

      <div className="vl"></div>

      <div className="pmcontent">
        <h1>Chair's Message</h1>
        <p>
          The Department of Education aims to ensure the global spread of bioethics education. This may be achieved by continuous support and facilitation of the various bioethics units to pursue enhanced and effective local and international collaboration with intensified professional relations with academic institutions and other partners. This will result in continual and direct access to bioethics education, systematic inquiry and analysis with the larger aim of making it possible for participants to put bioethics theory into practice.
        </p>

        <Link to="/pm" style={{ width: "fit-content", alignSelf: "end", textDecoration: "none" }}>
          <span style={{ color: "#01471B" }}>
            Read more {">"}
            {">"}
          </span>
        </Link>
      </div>
    </div>
  );
}

export default PrincipalMessage;
