import React, { useRef } from "react";
import "../.././Style/AboutUs/ucbsa.scss";
import OurTeam from "./OurTeam";
import Vision from "./Vision/Vision";
import ContactUs from "./ContactUs/ContactUs";
import { Link } from 'react-router-dom'

function IJDA() {
  const ContactRef = useRef(null);
  const ObjectiveRef = useRef(null);
  const VisionRef = useRef(null);

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop - 120,
      behavior: "smooth",
    });
  };
  return (
    <div className="ucbsamain">
      <div className="ucbsatop">
        <div className="ucbsaleft">
          <h3>The International Junior Doctors’ Association</h3>
          {/* <h3>STUDENT ASSOCIATION INC.</h3> */}
          {/* <h6>Reg No. A0114595P Victoria, Australia</h6> */}
        </div>

        <div className="ucbsaright">
          <div className="ucbsabuttons">
            <Link to="/ijda/#vision" onClick={() => { handleScroll(VisionRef.current); }}>
              <button className="ucbsabutton button-74">Objectives</button>
            </Link>
            <a href="https://www.icb-ijda.com" target="_blank" rel="noopener noreferrer">
              <button className="ucbsabutton button-74">Know More </button>
            </a>
            <Link to="/ijda/#objective" onClick={() => { handleScroll(ObjectiveRef.current); }}>
              <button className="ucbsabutton button-74">Join Us </button>
            </Link>
            <Link to="/ijda/#contact" onClick={() => { handleScroll(ContactRef.current); }}>
              <button className="ucbsabutton button-74">Contact Us </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="ucbsabottom">
        {/* <div class  */}

        <div className="bottomcontent">
          <p>
            The International Junior Doctors’ Association (IJDA) is a registered voluntary association of
            junior doctors which is an integral part of the International Chair in Bioethics WMA
            Cooperating Centre (Formerly known as the UNESCO Chair in Bioethics, Haifa). The
            formation of IJDA was announced on 19 th October 2022 during the World Bioethics Day
            celebration in the presence of the International Steering Committee members of the
            International Chair in Bioethics WMA cooperating centre. IJDA is committed to conduct its
            functioning towards the declared objectives without any systematic, institutionalized or
            contextual discrimination among and beyond its members.
          </p>
        </div>

        <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1672205492/doctor_consultation_02-removebg-preview_hyxmsi.png" alt="" />
      </div>


      <Vision VisionRef={VisionRef} ObjectiveRef={ObjectiveRef} />
      {/* <OurTeam/> */}
      <ContactUs ContactRef={ContactRef} />
    </div>
  );
}

export default IJDA;
