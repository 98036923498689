import React from 'react'
import './ContactUs.scss'
import { MdPhone, MdHome, MdWeb } from 'react-icons/md'


function ContactUs() {
    return (
        <div id="UCBSA-contact">
            {/* <h1>Get  Involved</h1> */}
            <div className='row'>
                {/* <div className='col-lg-6 contact-img'>
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1649701457/2937927-removebg-preview_ikl3m5.png"></img>
                </div> */}
                <div className='col-lg-10 '>
                    <ol>
                        <li>
                            <h2>History</h2>
                        </li>
                        <li>The UNESCO Chair in Bioethics Haifa was established on June 24th 2001, to coordinate and stimulate an international network of institutes for medical ethics associating higher education institutes in developed and developing countries to develop an up-to-date curriculum in medical ethics and medical education that will satisfy the requirements of medical schools and institutions of the world. After 20 years of the UNESCO Chair, Haifa’s outstanding contributions it was deemed the base and foundation for the inauguration of the International Chair in Bioethics and the World Medical Association cooperating centre and the Global Network of Bioethics Medical, Health Professions Education to continue the more specific focus on Bioethics, Medical and Health Professions education globally.
                           <br />
                        The Global Network strives to encourage moral behaviour, professionalism, and training in Medical and Health Professions Education by facilitating collaboration, research and knowledge exchange and shared decision-making to develop best practices among professionals in bioethics, medical and health professions education, and healthcare worldwide.
                        <br />Recognising the importance of ethics education in medical and health professions curriculum, the Global Network of Bioethics, Medical and Health Professions Education was formed as a follow on from the UNESCO Chair in Bioethics Haifa to coordinate and stimulate an International Network of Institutes for Medical Ethics associating higher education institutes in both developed and developing countries. The network envisions a world where ethical practices and moral obligations are at the forefront of healthcare, promoting the highest standards of professionalism and patient care. Our mission is to improve the quality of education and training in bioethics for medical and health professions education by designing and developing curricula, teaching materials, and resources with the latest updates on medical ethics education to satisfy the moral requirements of medical schools and institutions worldwide.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default ContactUs

// const ContactComponent = ({icon, heading,desc,descd})=>(
//     <div className='contact__item'>
//         <div className='contact__item-icon'>
//             <div>{icon}</div>
//         </div>
//         <div className='contact__item-text'>
//             <div className='contact__item-text-heading'>{heading}</div>
//             <div className='contact__item-text-desc'>{desc}</div>
//             <div className='contact__item-text-desc'>{descd}</div>
//         </div>
//     </div>
// )

// const ContactUs = () => {
//   return (
//     <div className='contact__container'>
//         <div className="contact__container__heading"><h4>Get Involved</h4></div>
//         <div className='contact__container-flex'>
//             <ContactComponent icon={<MdPhone size={48}/>} heading="Contact Us" desc="+91-9810525074"  descd="secretary.ucbsa-intl@unesco-bioethics-chair.org"/>
//             <ContactComponent icon={<MdHome size={48}/>} heading="Address" desc="71 Cleeland Street Melbourne, 3175, Australia"/>
//             <ContactComponent icon={<MdWeb size={48}/>} heading="Website" desc="www.edu.unesco-bioethics-chair.org"/>
//         </div>
//     </div>
//   )
// }

