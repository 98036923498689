import { React, useEffect } from "react";
import "../.././Style/AboutUs/common.scss";
import "../../Style/CoursesAndRecognition/TeacherCourses.scss";
import $ from "jquery";

function UG() {

  const miscCourses = [
    {
      img: "https://res.cloudinary.com/unesco-admin/image/upload/v1649696146/anaesthetic_idkbjh.png",
      courseName:
        "Post graduate international certificate course for anaesthesiologists on principles of bioethics and human rights",
      links: "https://res.cloudinary.com/unesco-admin/image/upload/v1672986040/ANAESTHETISTS_BROCHURE_glhqek.pdf",
    },
    {
      img: "https://res.cloudinary.com/unesco-admin/image/upload/v1649696146/reserch_wn4eku.png",
      courseName:
        "Post graduate international certificate course for dentists on principles of bioethics and human rights",
      links: "https://res.cloudinary.com/unesco-admin/image/upload/v1672986769/DENTISTS_BROCHURE_FINAL_compressed_evf35b.pdf",
    },
    {
      img: "https://res.cloudinary.com/unesco-admin/image/upload/v1649696146/dentist_r9zu7y.png",
      links: "https://res.cloudinary.com/unesco-admin/image/upload/v1672986599/PHYSIOTHERAPISTS_BROCHURE_FINAL_xwkn0m.pdf",
      courseName:
        "Post graduate international certificate course for physiotherapists on principles of bioethics and human rights",
    },
    {
      img: "https://res.cloudinary.com/unesco-admin/image/upload/v1649688909/HR_Dept_bt9ozg.png",
      courseName:
        "Certificate workshop for students on medical research and clinical trials",
      links: "https://res.cloudinary.com/unesco-admin/image/upload/v1672986058/CERTIFICATE_WORKSHOPS_ETHICS_dquclw.pdf",
    },
  ];

  const MiscCourses = (props) => {
    const classstyle = `col-md-4 course-card card${(props.no % 3) + 1}`;
    return (
      <div className={classstyle}>
        <h3>
          <span>{props.no + 7}.</span> {props.courseName}
        </h3>
        <a href={props.links} target="_blank">Click Here To View Brochure.</a>
      </div>
    );
  };

  return (
    <div id="courses" className="aboutHead">
      <h1>Courses</h1>
      <div className="background">
        <h2>Background:</h2>
        <p>
          The Department of Education has a wide range of educational programs
          and courses tailored to specific populations of faculty and students
          in healthcare education. This includes all the various disciplines in
          the field of medical and healthcare sciences for students – both
          undergraduate and postgraduate, healthcare professionals and
          practicing doctors in all specialties and sub-specialties. The
          Department of Education offers international programs to facilitate
          bioethics education at university level both for undergraduate and
          post-graduate education as well as refresher courses for professionals
          engaged in private practice. These programs empower participants,
          address the challenges they face in real-life situations through
          acquired methodological approaches and enhance their analytical skills
          to recognize ethical issues that are applicable both in their clinical
          practice and biomedical research.
        </p>
        <p>
          The students are sensitized throughout their healthcare training and
          curriculum to recognize and to be aware of the many bioethical issues
          that arise in the routine delivery of healthcare. They are trained to
          recognize, reflect and act in a befitting manner that upholds the
          principles of the Universal Declaration. This will ensure good
          doctor-patient communication and the efficient and effective delivery
          of healthcare especially in regions where resources are limited.
        </p>
        <p>
          The Department envisions to develop a common platform for supporting
          and advancing Bioethics education and good clinical practices by
          educating members of the UNESCO network. The Department of Education
          cultivates and train experts in the area of ethics teaching and design
          teaching curricula to form a valuable collaborative resource to
          further high-quality Bioethics education. Towards this aim,
          information on existing teaching programs is being collected to help
          focus on more systematic evidence gathering and analysis of data to
          identify the best practices of education and promulgate them to
          inspire the action of bioethics educational programs both in and
          outside the units.
        </p>
        <p>
          With a view to pursue international programs in Bioethics, the
          Department of Education functions based on the framework of agreements
          that have been signed between the International Chair in Bioethics
          (Haifa) and Universities/University that have/has both the mandate and
          duty to address the new challenges.
        </p>
      </div>

      <div>
        <h1 className="main-heading">
          Programs of the Department of Education :-{" "}
        </h1>
      </div>

      <div className="row courses">
        <div className="col-md-4 course-card card1">
          <h3>
            <span>1.</span> Faculty Development program:{" "}
          </h3>
          <p>
            The Department offers the bioethics teaching faculty development
            programs. These courses offer “how to teach” bioethics using the
            evidence-based special bioethics teaching methodology. The focus
            here includes the integration of the bioethics curriculum vertically
            and horizontally into the regular curriculum of medical and health
            sciences.
          </p>
          <a href="https://res.cloudinary.com/unesco-admin/image/upload/v1672980798/3T_BROCHURE_guyuyk.pdf" target="_blank">Click Here To View Brochure.</a>
        </div>
        <div className="col-md-4 course-card card2">
          <h3>
            <span>2.</span> Postgraduate certificate in the Principles of
            Bioethics and Human rights applied in medical and health science
            education:
          </h3>

          <p>
            This postgraduate course focuses on “what to teach” and its
            application in medical and health science education and an
            introduction to applied ethics in medical education. This course
            covers a real-time virtual distant education over 24 weeks.
          </p>
          <a href="https://res.cloudinary.com/unesco-admin/image/upload/v1652680930/GENERAL_OVERSEAS_BROCHURE_1_o5we4k.pdf" target="_blank">Click Here To View Brochure.</a>
        </div>
        <div className="col-md-4 course-card card3">
          <h3>
            <span>3.</span> Postgraduate Diploma in the principles of Bioethics
            and Health Law applied in medical and health science education:
          </h3>
          <p>
            This is a sequential follow-up of the certificate course and focuses
            on bringing in 15 advance modules on bioethics application in
            medical and healthcare with a specific focus on the didactic
            teaching methodology. This will covered-over 40 weeks.
          </p>
          <a href="https://res.cloudinary.com/unesco-admin/image/upload/v1672986599/PG_DIPLOMA_OVERSEAS_wbhgcc.pdf" target="_blank">Click Here To View Brochure.</a>
        </div>

        <div className="col-md-4 course-card card1">
          <h3>
            <span>4.</span> Masters of Sciences in
            Bioethics and Health law applied in medical and health science education:
          </h3>

          <p>
            After the completion of PG certificate & PG diploma, participants
            will proceed with the building & completion of research & thesis
            followed by face to face defending the thesis.
          </p>
          <a href="" target="_blank">Click Here To View Brochure.</a>
        </div>
        <div className="col-md-4 course-card card2">
          <h3>
            <span>5.</span> Postgraduate certificate in teaching medical
            humanities in medical and health science education:
          </h3>
          <p>
            This course features “how & what to teach” in medical humanities
            which includes the anthropological approach to understand medical
            teaching, the use of narrative medicine, literature as a teaching
            tool, medical sociology in medical education with understanding
            patient’s experiences including suffering and compassion. Teaching
            methodology and skills, art in teaching and experiential teaching
            modalities. This is a 20 weeks course.
          </p>
          <a
            href="https://res.cloudinary.com/unesco-admin/image/upload/v1652680870/MED_HUM_SMALL_FLYER_akrb6v.pdf"
            target="_blank"
          >
            Click Here To View Brochure.
          </a>
        </div>
        <div className="col-md-4 course-card card3">
          <h3>
            <span>6.</span> The Students’ International Certificate Course in
            the principles of Bioethics and Human rights applied in medical and
            health science education:
          </h3>
          <p>
            25 modules in 24 weeks long undergraduate course by International
            faculties of Dept of Education for medical & health science students
            worldwide.
          </p>
          <a
            href="https://res.cloudinary.com/unesco-admin/image/upload/v1672986027/ABROAD_STUDENTS_BROCHURE_k2udo0.pdf"
            target="_blank"
          >
            Click Here To View Brochure.
          </a>
        </div>
      </div>

      <div className="row">
        {miscCourses.map((value, index) => {
          return (
            <MiscCourses
              img={value.img}
              courseName={value.courseName}
              no={index}
              links={value.links}
            />
          );
        })}
      </div>
    </div>
  );
}

export default UG;
