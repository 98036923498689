export default  [
    {
        name : 'Prof. Russell DSouza',
        location : 'Melbourne, Australia',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981415/Dr_Russell_DSouza_-_Copy_fabqtz.jpg'
    },
    {
        name : 'Prof. Mary Mathew',
        location : 'Manipal, India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981927/Professor_Mary_Mathew_wbgi02.jpg'
    },
    {
        name : 'Prof. Gerhard Fortwengel',
        location : 'Hannover, Germany',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981961/Prof_Gerhard_Fortwengel_e8nrii.png'
    },
    {
        name : 'Prof. William Pinsky',
        location : 'USA',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644003655/William_Pinsky_mlzlb7.jpg'
    },
    {
        name : 'Prof. Ronald Harden',
        location : 'Dundee, United Kingdom',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004491/Prof_Ronald_Harden_ajnhvy.jpg'
    },
    
    {
        name : 'Professor Ricardo Leon-Borqez',
        location : 'Mexico',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1686468921/Ricardo-Leon-Borquez-A_zxmmns.jpg'
    },
    {
        name : 'Professor Emiola Oluwabunmi Olapade-Olaopa',
        location : 'Nigeria',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1686469043/Professor_Emiola_Oluwabunmi_Olapade-Olaopa_et3tvi.jpg'
    },

    {
        name : 'Dr Marcos A. Nunez ',
        location : 'Dominican Republic',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1686469114/Dr.Marcos-Nunez-PAFAMS-President-Photo-682x1024_qyco9h.jpg'
    },

    {
        name : 'Professor Bonny L. Dickinson ',
        location : 'USA',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1686469138/Dr_Bonny_Dickinson_USA_lidfvr.jpg'
    },

    {
        name : 'Baroness Prof Ilora Finlay',
        location : 'Cardiff, United Kingdom',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644001918/Baroness_Finlay_2018_002_jqznhp.jpg'
    },
    {
        name : 'Prof. Vedprakash Mishra',
        location : 'Wardha India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1703666940/tlgvcygdwum1nliqarsf.jpg'
    },
    {
        name : 'Prof. Derek DSouza',
        location : 'Pune, India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1643982008/Dr_Derek_Dsouza_audgyt.jpg'
    },
    {
        name : 'Prof. Joseph Thornton',
        location : 'Florida, USA',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1643982033/Prof_Joseph_Thornton_Uni_of_Florida_USA_o5apkn.png'
    },
    {
        name : 'Prof. Stacy Gallin',
        location : 'New Jersey USA',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002106/Dr_Stacy_Gallin_USA_e9uxsx.jpg'
    },
    {
        name : 'Prof. Vivienne Harpwood',
        location : 'United Kingdom',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002200/Vivienne_Harpwood_-_Chair_qxtw1m.jpg'
    },
    {
        name : 'Prof. Princy Palatty',
        location : 'Kochi, India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002274/princy-louis-palatty_-_Copy_cxwhsd.jpg'
    },
    {
        name : 'Prof. Daniella Keidar',
        location : 'Haifa, Israel',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1645983872/Prof_Daniella_Kediar_rvs2gr.jpg'
    },    {
        name : 'Prof. Shabbir Amanullah',
        location : 'Ontario, Canada',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002427/Prof_Shabbir_Amanullah_London_Canada_yfsew0.jpg'
    },    {
        name : 'Prof. Lincoln Lopez Ferrero',
        location : 'Brazil',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002506/Dr_Lincoln_Ferreira_imrnqo.jpg'
    },    {
        name : 'Prof. Rui Nunes',
        location : 'Porto, Portugal',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002595/Prof._Rui_Nunes_szpyk4.png'
    },
    {
        name : 'Prof. Otmar Kloiber',
        location : 'Germany',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644003966/Otmar_Kloiber_-_Copy_eusypd.jpg'
    },
    {
        name : 'Prof. Moty Benyakar',
        location : 'Argentina',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004113/Prof_Moty_Benyakar_Argentine_dj3e31.jpg'
    },
    {
        name : 'Prof. Shai Linn',
        location : 'Israel',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004244/Prof_Shai_Linn_Israel_svjibl.jpg'
    },
    {
        name : 'Prof. Rakic Vojin',
        location : 'Serbia',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004337/Vojin_Rakic_h8f0n6.jpg'
    },
    {
        name : 'Prof. Ravi Wankhedkar',
        location : 'New Delhi, India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004556/Dr_Ravi_Wanhhedkar_kqpes3.jpg'
    },
    {
        name : 'Prof. Madelina Patricio',
        location : 'Portugal',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004624/Prof_Madalena_Patricio_pnam0w.jpg'
    },
    {
        name : 'Prof. Harold Bursztajn',
        location : 'Boston, USA',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004688/Professor_Harold_J_Bursztajn_USA_qs3car.jpg'
    },
    {
        name : 'Prof. Rajiv Ahluwalia',
        location : 'New Delhi, India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004739/Dr._Rajiv_Ahluwalia_hpguqt.jpg'
    },
    {
        name : 'Prof. Shailendra Saraf',
        location : 'India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004810/Dr_Shailendra_Saraff_Vice_Chancellor_Durg_University_Head_Pharmacy_Bioethics_Indian_Program_UNESCO_Chair_in_Bioethics_el0pd9.jpg'
    },
    {
        name : 'Prof. David Gordon',
        location : '',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004899/prof.-David_Gordon_1_htffu6.jpg'
    },
    {
        name : 'Prof. Peter Mossey',
        location : 'United Kingdom',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004962/Prof._Peter_A._Mossey.jpg_r9iz04.jpg'
    },
    {
        name : 'Prof. Neelam Mishra',
        location : 'India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644005084/Dr._Neelam_Mishra_v9sjpi.jpg'
    },
    {
        name : 'Prof. Ani Grace Kalaimathi',
        location : 'Chennai, India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644005141/Dr._Ani_Grace_dayo8a.png'
    },
    {
        name : 'Prof. S. Geetalakshmi',
        location : 'Chennai, India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644005274/geethalakshmi_db84pv.jpg'
    },
    {
        name : 'Prof. Satheesh Bhandary',
        location : 'Mangalore, India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644005345/Dr_Satheesh_Bhandary_b0dv35.png'
    },
    {
        name : 'Prof. Rajeev Tandon',
        location : ' Kalamazoo, USA',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1652550302/Dr_Rajeev_Tandon_mv7fhg_enl54q.jpg'
    },
]