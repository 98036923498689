export default [

    {
        name: 'Prof. Russell DSouza',
        location: 'Melbourne, Australia',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981415/Dr_Russell_DSouza_-_Copy_fabqtz.jpg'
    },
    {
        name: 'Prof. Mary Mathew',
        location: 'Manipal, India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981927/Professor_Mary_Mathew_wbgi02.jpg'
    },
    {
        name: 'Prof. Joseph Thornton',
        location: 'Florida, USA',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1643982033/Prof_Joseph_Thornton_Uni_of_Florida_USA_o5apkn.png'
    },
    {
        name: 'Prof. Krishna M Surapaneni',
        location: ' India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1652549323/Prof.Surapaneni_Krishna_Mohan_gndh85.jpg'
    },
    {
        name: 'Prof.Rajiv Tandon',
        location: 'USA',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1652550302/Dr_Rajeev_Tandon_mv7fhg_enl54q.jpg'
    },
    {
        name: 'Prof. Vedprakash Mishra',
        location: 'Wardha India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1703666940/tlgvcygdwum1nliqarsf.jpg'
    },
    {
        name: 'Prof. Rui Nunes',
        location: 'Porto, Portugal',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002595/Prof._Rui_Nunes_szpyk4.png'
    },
    {
        name: 'Prof. Vivienne Harpwood',
        location: 'United Kingdom',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002200/Vivienne_Harpwood_-_Chair_qxtw1m.jpg'
    },
    {
        name: 'Prof. Derek DSouza',
        location: 'Pune, India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1643982008/Dr_Derek_Dsouza_audgyt.jpg'
    },
    {
        name: 'Prof. Shabbir Amanullah',
        location: 'Ontario, Canada',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002427/Prof_Shabbir_Amanullah_London_Canada_yfsew0.jpg'
    },
    {
        name: 'Prof. Selva Titus',
        location: 'UAE',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1652549685/Prof.Selva_Titus_littcl.jpg'
    },
    {
        name: 'Prof. Princy Palatty',
        location: 'Kochi, India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002274/princy-louis-palatty_-_Copy_cxwhsd.jpg'
    },
    {
        name: 'Prof. Ali Al-dabbagh',
        location: 'Iraq',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1652550041/Prof._Ali_Al-dabbagh_o1gzwe.jpg'
    },
    {
        name: 'Prof. Shiv Joshi',
        location: 'India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1652623657/shiv_joshi_koedb4.jpg'
    },
]