import React from "react";
import { useState, useEffect } from "react";
import "../.././Style/AboutUs/AboutUs.scss";
import "../.././Style/AboutUs/Committees.scss";
import LoadingScreen from "react-loading-screen";
import axios from "axios";

function Webinar() {
  const [webinar, setWebinar] = useState([]);
  const [loading, setLoading] = useState(true);

  const getWebinar = async () => {
    await axios.get("https://unesco-backend.vercel.app/webinar").then((res) => {
      const data = res.data;
      // data.reverse()
      setWebinar(data);
    });
    setLoading(false);
  };

  useEffect(() => {
    getWebinar();
  }, []);

  return (
    <div id="committees" className="aboutHead">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Getting Webinars..."
      ></LoadingScreen>

      <h1>Our Webinars</h1>
      <table className="table table-bordered align-middle table-responsive">
        <thead className="table-light">
          <tr>
            <th className="sno">Series</th>
            <th className="topic">Topic</th>
            <th className="web">Webinar</th>
            <th className="web">Summary</th>
          </tr>
        </thead>
        {webinar.length > 0
          ? webinar.map((value, index) => {
              return (
                <tbody>
                  <tr>
                    <td>{value.series}</td>
                    <td>{value.topic}</td>
                    <td>
                      {value.videourl ? (
                        <a href={value.videourl} target="_blank">
                          <button class="button-74" role="button">
                            Recording
                          </button>
                        </a>
                      ) : null}
                    </td>
                    <td>
                      {value.summaryurl ? (
                        <a href={value.summaryurl} target="_blank">
                          <button class="button-74" role="button">
                            Summary
                          </button>
                        </a>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              );
            })
          : null}
      </table>
    </div>
  );
}

export default Webinar;
