import React from "react";
import "./Cpd.scss";
const Cpd = () => {
  return (
    <div className="main">
      <h1 className="heading">CPD Session</h1>
      {/* <div className="image">
        <img
          src="https://ibellstore.com/images/no-item-found-here.png"
          alt=""
          srcset=""
          className="not-found"
        />
      </div> */}
      <div className="cpd">
        <div className="left">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1713620513/WhatsApp_Image_2024-04-20_at_7.09.49_PM_itb8cm.jpg"
            alt=""
          />
        </div>
        <div className="right">
          🌟 <b>You're Invited!</b> 🌟
          <br />
          <br />
          Join us for an enlightening CPD session on{" "}
          <b>
            {" "}
            "From Vision To Reality: Lessons Learned From The First Eye
            Transplant And Ethical Reflections In Transplant Medicine"
          </b>{" "}
          organized by the{" "}
          <b>
            Department of Education, International Chair in Bioethics, World
            Medical Association Cooperating Centers, University of Porto
            (Formerly UNESCO Chair)
          </b>
          .
          <br />
          <br />
          <b>🗓️ Date: April 23, 2024 (Tuesday) </b> <br />
          <b>⏰ Time: 6 PM to 7 PM IST (1:30 PM London Time)</b> <br />
          <b>🎙️ Speaker: Bruce Elliot Gelb</b> <br />
          <br />
          We look forward to your participation! Scan the QR code on the image
          to register now! ✨
          <br />
          <br />
          <a
            href="https://clrn.in/pxy9o"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Click here to register now!
          </a>
          <br />
          <br />
          <b>Steps to follow for Registration: </b>
          <br />
          <ul>
            <li>1. Click on the provided link.</li>
            <li>
              2. Complete all required fields in the form accurately. When
              entering your contact number, ensure it includes the appropriate
              country dialing code.
            </li>
            <li>3. For Medical Council Registration Number, Put 0000</li>
            <li>4. State Council Name - Department of Education.</li>
            <li>3. Submit the form.</li>
          </ul>
          <br />
          <b>To join the session:</b>
          <br />
          1. Utilize the same link to access the session 15 minutes prior to the
          scheduled start time, and confirm your contact number.
        </div>
      </div>
    </div>
  );
};

export default Cpd;
