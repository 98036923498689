import React from "react";
import CountUp from "react-countup";
import "../.././Style/LandingPage/Numbers.scss";
import VisibilitySensor from "react-visibility-sensor";

function Numbers() {
  return (
    <div className="numbers">
      <h1> By Numbers</h1>
      <div className="groups">
        <div className="group">
          <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1643984714/Vector_1_acx7dh.png" alt="img1" />
          <VisibilitySensor partialVisibility >
            {({ isVisible }) => (
              <div className="counter">
                {isVisible ? (
                  <CountUp
                    start={0}
                    end={1000}
                    duration={1}
                    suffix="+"
                    
                  />
                ) : null}
                <p>Co-Learners<span className="and"> & </span>trainees</p>
              </div>
            )}
          </VisibilitySensor>
        </div>

        <div className="group">
          <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1643984714/Vector_gw2cl5.png" alt="img2" />
          <VisibilitySensor partialVisibility >
            {({ isVisible }) => (
              <div className="counter">
                {isVisible ? (
                  <CountUp
                    start={0}
                    end={200}
                    duration={1}
                    suffix="+"
                    
                  />
                ) : null}
                <p>Faculty Members</p>
              </div>
            )}
          </VisibilitySensor>
        </div>
        <div className="group">
          <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1643984715/Group_wvavnn.png" alt="img3" />
          <VisibilitySensor partialVisibility >
            {({ isVisible }) => (
              <div className="counter">
                {isVisible ? (
                  <CountUp
                    start={0}
                    end={100}
                    duration={1}
                    suffix="+"
                    
                  />
                ) : null}
                <p>Units across universities worldwide</p>
              </div>
            )}
          </VisibilitySensor>
        </div>
      </div>
    </div>
  );
}

export default Numbers;
