export default  [
    {
        name : 'Prof. Russell DSouza',
        location : 'Melbourne, Australia',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981415/Dr_Russell_DSouza_-_Copy_fabqtz.jpg'
    },
    {
        name : 'Prof. Mary Mathew',
        location : 'Manipal, India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981927/Professor_Mary_Mathew_wbgi02.jpg'
    },
    {
        name : 'Prof. Gerhard Fortwengel',
        location : 'Hannover, Germany',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981961/Prof_Gerhard_Fortwengel_e8nrii.png'
    },
    {
        name : 'Baroness Prof Ilora Finlay',
        location : 'Cardiff, United Kingdom',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644001918/Baroness_Finlay_2018_002_jqznhp.jpg'
    },
    {
        name : 'Prof. Derek DSouza',
        location : 'Pune, India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1643982008/Dr_Derek_Dsouza_audgyt.jpg'
    },
    {
        name : 'Prof. Joseph Thornton',
        location : 'Florida, USA',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1643982033/Prof_Joseph_Thornton_Uni_of_Florida_USA_o5apkn.png'
    },
    {
        name : 'Prof. Stacy Gallin',
        location : 'New Jersey USA',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002106/Dr_Stacy_Gallin_USA_e9uxsx.jpg'
    },
    {
        name : 'Prof. Vivienne Harpwood',
        location : 'United Kingdom',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002200/Vivienne_Harpwood_-_Chair_qxtw1m.jpg'
    },
    {
        name : 'Prof. Princy Palatty',
        location : 'Kochi, India',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002274/princy-louis-palatty_-_Copy_cxwhsd.jpg'
    },
    {
        name : 'Prof. Daniella Keidar',
        location : 'Haifa, Israel',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1645983872/Prof_Daniella_Kediar_rvs2gr.jpg'
    },    {
        name : 'Prof. Shabbir Amanullah',
        location : 'Ontario, Canada',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002427/Prof_Shabbir_Amanullah_London_Canada_yfsew0.jpg'
    },    {
        name : 'Prof. Lincoln Lopez Ferrero',
        location : 'Brazil',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002506/Dr_Lincoln_Ferreira_imrnqo.jpg'
    },    {
        name : 'Prof. Rui Nunes',
        location : 'Porto, Portugal',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002595/Prof._Rui_Nunes_szpyk4.png'
    },    {
        name : 'Prof. Alvin Chan',
        location : 'Hong Kong',
        image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1652623651/alvin_chan_yr9n0p.jpg'
    },
]