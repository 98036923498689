import React from "react";
import "../.././Style/LandingPage/Quotes.scss";

function Quotes() {
  return (
    <div className="quotes">
      <h3 className="mt-2">Vision statement of the Department of Education</h3>
      <p>
        To disseminate and ensure the evidence-based bioethics education using
        the teaching learning methodology which is to be integrated in medical
        and health science education internationally.
      </p>
    </div>
  );
}

export default Quotes;
