import React from "react";
import { useEffect } from "react";
import "../.././Style/AboutUs/AboutUs.scss";
import "../.././Style/AboutUs/PrincipalMsg.scss";

function PrincipalMsg() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="PrincipalMsg aboutHead">
      <img
        src="https://res.cloudinary.com/unesco-admin/image/upload/v1643981415/Dr_Russell_DSouza_-_Copy_fabqtz.jpg"
        className="prinImg"
        alt=""
      ></img>
      <div className="TopSection">
        <h1>Chair's Message</h1>

        <div className="hl"></div>
        <img
          src="https://res.cloudinary.com/unesco-admin/image/upload/v1643981415/Dr_Russell_DSouza_-_Copy_fabqtz.jpg"
          className="mobPrinImg"
          alt=""
        ></img>
      </div>

      <div className="message">
        <div className="pvl"></div>

        <div className="msgContent">
          <p>
            ⠀⠀The Department of Education aims to ensure the global spread of bioethics education. This may be achieved by continuous support and facilitation of the various bioethics units to pursue enhanced and effective local and international collaboration with intensified professional relations with academic institutions and other partners. This will result in continual and direct access to bioethics education, systematic inquiry and analysis with the larger aim of making it possible for participants to put bioethics theory into practice.

          </p>
          <p>
            The Department will oversee and assist bioethics units design, plan and implement the UNESCO Core Curriculum in Bioethics that will enable healthcare practitioners and students to advance their capacity for decision making on ethical issues and human rights considerations of medicine, health care and life sciences. This is expected to translate them into better and more efficient healthcare service providers.          </p>
          <div className="designation">
            <h5>May God bless us all.</h5>
            <h5>Prof. RUSSELL DSOUZA</h5>
            <h5>
              (Chair & Head. Department of Education <br /> International Chair in Bioethics <br />& WMA Co-Operating Centre, <br /> University of Porto, Melbourne, Australia){" "}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrincipalMsg;
