import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../.././Style/LandingPage/Announcement.scss";
import quotes from "../quotes.png";
import { Link } from "react-router-dom";

function Announcement() {
  return (
    <div className="an">
      <div className="an-main">
        <div className="an-left">
          <div className="an-buttons">
            <Link to="/webinars"><button className="an-button button-74">Our Webinars</button></Link>
            <Link to="/about"> <button className="an-button button-74"> Know More </button></Link>
            <Link to="/contact"><button className="an-button button-74">Join Us</button></Link>
          </div>
          <img
            src="https://i.ibb.co/480Df6G/Vector-7.png"
            alt=""
            className="vector"
          />
        </div>
        <div className="an-right">
          <div className="an-headings">
            <h3>
              DEPARTMENT OF EDUCATION, INTERNATIONAL CHAIR IN BIOETHICS & WMA CO-OPERATING CENTRE, UNIVERSITY OF PORTO, MELBOURNE, AUSTRALIA
            </h3>
            <h5>
              FORMERLY UNESCO CHAIR IN BIOETHICS, HAIFA
            </h5>
          </div>

          <div className="an-box">
            <p>
              To build, activate, co-ordinate & stimulate Bioethics education
              through an International Network of Units
            </p>
          </div>
        </div>
      </div>

      <div className="an-bottom">
        <img
          src="https://i.ibb.co/480Df6G/Vector-7.png"
          alt=""
          className="vector2"
        />
        <img
          src="https://res.cloudinary.com/unesco-admin/image/upload/v1643981415/Dr_Russell_DSouza_-_Copy_fabqtz.jpg"
          alt=""
          className="an-image"
        />

        <div className="an-right">
          <div className="an-headings">
            <h3>Prof. Russell Franco D'Souza</h3>
            <h5 id="pos">
              Chair & Head. Department of Education International Chair in Bioethics & WMA Co-Operating Centre, University of Porto, Melbourne, Australia

            </h5>
          </div>

          <div className="an-bottom-content">
            <div className="an-bottom-para">
              <img src={quotes} alt="" />
              <p>
                Every Crisis is about ethics. <br /> The Nuremberg trials
                brought us the importance of the principles of autonomy, consent
                and human integrity, pandemic revealed the importance of
                solidarity, justice and equity.
              </p>
            </div>

            <div className="triangle"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcement;
