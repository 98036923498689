import React, { useEffect, useRef } from "react";
// import './Comittees.scss'
import "../.././Style/AboutUs/common.scss";
import "../.././Style/AboutUs/Committees.scss";
import MemberData1 from "./MembersData1";
import MemberData2 from "./MembersData2";
import MemberData3 from "./MembersData3";
import { useLocation } from "react-router-dom";


function findPos(obj) {
  var curtop = -150;
  if (obj.offsetParent) {
      do {
          curtop += obj.offsetTop;
      } while (obj = obj.offsetParent);
  return [curtop]-10;
  }
}

const ComitteeMember = ({ name, location, image }) => (
  <div className="member">
    <div
      className="member__image"
      style={{ background: `url(${image}) center center/cover` }}
    ></div>
    <div className="member__name">{name}</div>
    <div className="member__location">{location}</div>
  </div>
);

const Committees = () => {
  const {search} = useLocation();
  const ref = useRef()
  useEffect(()=>{

    if (search==='?international') {
      window.scroll(0,findPos(ref.current));
    }
  
  }, [])
  return (
    <>
      <div className="aboutHead">
        <h1>Council and Committee</h1>
      
      <div className="sub-heading">
        Structure of the Department of Education
      </div>
      <div className="main-text">
        <p>
          The Department of Education will be composed of members from those who
          responded to the International Chair in Bioethics’ call for interest
          to serve in the department.
        </p>
        <ul>
          <li>
            The Department of Education will be under the direct leadership of a
            steering committee.
          </li>
          <li>
            The leadership will be provided by Chair
            <span className="and"> & </span>Head of the Department of Education
            appointed by the International Chair in Bioethics (Haifa).
          </li>
          <li>
            The head of the Department of Education will be assisted by regional
            directors for each of the international zones such as Americas,
            Europe, West Asia<span className="and"> & </span>Africa, South-East
            Asia and Pacific Region including Far East.
          </li>
          <li>
            Each nation will have a country Head / Director who will be assisted
            by a National Executive Steering Committee (NESC). This committee
            will be responsible to ensure that the objectives of the Department
            of Education are realized and that guarantee that there is a system
            of continuous evaluation, monitoring and application of the
            departmental aims and objectives for quality assurance and
            excellence.
          </li>
        </ul>
        <div className="grid-heading">Executive Committee</div>
        <div className="member_grid">
          {MemberData1.map((member) => (
            <ComitteeMember
              name={member.name}
              location={member.location}
              image={member.image}
            />
          ))}
        </div>
        <div className="grid-heading">International Advisory Council</div>
        <div className="member_grid">
          {MemberData2.map((member) => (
            <ComitteeMember
              name={member.name}
              location={member.location}
              image={member.image}
            />
          ))}
        </div>
        <div className="grid-heading" id="international" ref={ref}>
          International Student Advisory Committee
        </div>
        <div className="member_grid">
          {MemberData3.map((member) => (
            <ComitteeMember
              name={member.name}
              location={member.location}
              image={member.image}
            />
          ))}
        </div>
      </div>
      </div>
    </>
  );
};

export default Committees;
