import { React, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Style/Activities/Galary.scss";
import axios from "axios";
import Masonry from "react-masonry-component";
import styled from "styled-components";
import ModalImage from "react-modal-image";
import LoadingScreen from "react-loading-screen";
import VisibilitySensor from "react-visibility-sensor";
import ImageCard from "../Image";

function Galary() {
  const [images, setImages] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getImages = async () => {
    setLoading(true);
    await axios
      .get("https://unesco-backend.vercel.app/corousel")
      .then((res) => {
        const data = res.data;
        setImages(data);
      });
    setLoading(false);
  };

  const getImage = async () => {
    setLoading(true);
    await axios.get("https://unesco-backend.vercel.app/gallery").then((res) => {
      const data = res.data;
      setImage(data);
    });
    setLoading(false);
  };

  const Content = styled(Masonry)`
    min-height: 80vh;

    & > div {
      margin: 1%;
      width: ${(props) => (100 - 2 * props.columns) / props.columns}%;
    }
  `;
  useEffect(() => getImages(), []);
  useEffect(() => getImage(), []);

  return (
    <div className="Galary">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Loading...."
      ></LoadingScreen>

      <VisibilitySensor scrollCheck={true}>
        <div className="main">
          {/* Slider Code */}
          <Slider {...settings}>
            {images.length > 0
              ? images.map((value, index) => {
                  return (
                    <div class="content_img">
                      <img
                        src={value.avatar}
                        className="Campus"
                        alt={`capmus-${index}`}
                      />
                      <p className="description">{value.description}</p>
                    </div>
                  );
                })
              : null}
          </Slider>

          {/* Gallery Code */}
          <div className="image-list">
            {image.length > 0
              ? image.map((value, index) => (
                  <ImageCard
                    image={value.avatar}
                    key={index}
                    description={value.description}
                  />
                ))
              : null}
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
}

export default Galary;
