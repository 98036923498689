import React from "react";
import "./Vision.scss";

const VisionCompponent = ({ heading, points, flexState, imgLink, imgWidth, link }) => {
  return (
    <div
      className={`vision__container ${!flexState ? 'vision__container__reverse' : ''}`}
    >
      <div className="vision-img-container" >
        <img
          src={imgLink}

          className={`vision-img-container__img ${flexState ? 'vision-img-container__img__largeImg' : ''}`}
          alt=""
        />
      </div>
      <div className="vision__container__text"   >

        <h4 className="vision__container__text__heading">{heading}</h4>
        <div className="vision__container__text__content">
          <ul>
            {points.map((point) => (
              <li>{point}</li>
            ))} {link ? <a href={link} target="_blank">ICBSA Module</a> : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

const Vision = () => {
  return (
    <>
      <VisionCompponent
        heading="Vision"
        points={[
          "Establish the Global Network of Bioethics, Medical, and Health Professions Education specialists.",
          "Promote ethical practices in healthcare and medical education globally.",
          "Foster a culture of collaboration, mutual learning, and innovation within the network.",
          "Integrate bioethics education with medical and health professions education to address emerging ethical issues with technological advances and artificial intelligence in healthcare.",
          "Create a leading platform for the exchange of knowledge and resources among professionals in the field."
        ]}
        flexState
        // link="https://res.cloudinary.com/unesco-admin/image/upload/v1685360852/ICBSA_II_5__compressed_ew3dtk.pdf"
        imgLink='https://res.cloudinary.com/unesco-admin/image/upload/v1686470232/mission_vision-600x370_nxsm5m.png'
        imgWidth='100%'

      />
      <VisionCompponent
        heading="Mission Statement"
        points={[
          "Advancing Bioethics Principles: The network is committed to advancing the understanding and application of bioethics principles in medical education and healthcare practice worldwide.",
          "Fostering a Global Network: The network aims to create a global community of healthcare professionals, educators, researchers, and policymakers dedicated to promoting bioethics principles and values in medical and healthcare education and practice.",
          "Comprehensive Approach: Through training, collaboration, research, education, and advocacy, the network strives to equip healthcare professionals with the knowledge, skills, and ethical frameworks needed to provide value-based ethical, high-quality, and compassionate care to their patients.",
          "Enhancing Education and Research: The network seeks to enhance education and evidence-based training in medical and bioethics education, as well as promote research in these fields.",
          "Collaboration and Knowledge Sharing: The network provides a platform for members to collaborate, share knowledge, best practices, and resources related to bioethics and medical education. It serves as a vital resource for offering accredited training to professionals and organizations within the network, aiming to be a global leader in integrating bioethics into medical science education worldwide."
        ]}
        imgLink='https://res.cloudinary.com/unesco-admin/image/upload/v1686470329/image-removebg-preview_tripa9.png'
        imgWidth='100%'
      />
    </>
  );
};

export default Vision;
