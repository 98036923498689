import React, { useState, useEffect } from "react";
import "../../Style/LandAndBuilding.scss";
import "../../Style/AboutUs/AboutUs.scss";
import axios from "axios";
import LoadingScreen from "react-loading-screen";

function LandAndBuilding() {
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const getPublications = async () => {
    setLoading(true);
    await axios
      .get("https://unesco-backend.vercel.app/publication")
      .then((res) => {
        const data = res.data;
        data.reverse();
        setPublications(data);
      });
    setLoading(false);
  };

  useEffect(() => getPublications(), []);

  return (
    <div>
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Loading Publications..."
      />
      <div className="LandAndBuilding aboutHead">
        <h1>Publications</h1>
        <div className="publication-grid">
          {publications.length > 0
            ? publications.map((value, index) => {
                return (
                  <div
                    data-toggle="modal"
                    data-target="#one"
                    className="col-lg-4 pub"
                  >
                    <a
                      href={value.link}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <img src={value.imgLink} /> <br />
                      <h3 className="file-name">{value.name}</h3>
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}

export default LandAndBuilding;
