import React from "react";
import "../.././Style/AboutUs/AboutUs.scss";
import "../.././Style/AboutUs/PrincipalMsg.scss";

function AboutSubContent({img,title,content, reverse}) {

  return (
    <div className={`PrincipalMsg aboutHead${reverse?' reverse-about':''}`} >
      <img src={img} className="prinImg" alt=""></img>
      <div className="TopSection">
          <h1>{title}</h1>
        <div className="hl"></div>
        <img src={img} className="mobPrinImg" alt=""></img>
      </div>

      <div className="message">
        <div className="pvl"></div>

        <div className="msgContent">
            <p>{content}</p>
        </div>
      </div>
    </div>
  );
}

export default AboutSubContent;
