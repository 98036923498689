import React from "react";
import "./WhyJoin.scss";

const WJItem = ({ icon, text }) => {
  return (
    <li className="wj__items__item">
      <div className="img">

        <img src={icon} alt="" />
      </div>
      <div className="wj__items__item__text">{text}</div>
    </li>
  );
};

const WhyJoin = () => {
  return (
    <div className="wj__main">
      <h3 className="wj__heading">Join Us</h3>
      <ul className="wj__items">
        <WJItem
          icon="https://res.cloudinary.com/unesco-admin/image/upload/v1650360677/download_xpxcpw-removebg-preview_kxzqxf.png"
          text="Exclusive right to establish bioethics units within their organisation and Leadership
 opportunities.
"
        />
        <WJItem
          icon="https://res.cloudinary.com/unesco-admin/image/upload/v1649357811/research_qzp7nx.png"
          text="Collaborative research opportunities on Integrated Bioethics.

"
        />
        <WJItem
          icon="https://res.cloudinary.com/unesco-admin/image/upload/v1649704027/876784_orjjig.png"
          text="1000+ registered members from over 50 countries within 6 months of incorporation"
        />
        <WJItem
          icon="https://res.cloudinary.com/unesco-admin/image/upload/v1649704027/4207230_tl7xq3.png"
          text="International Network of Professors for keynotes, workshops, conferences, etc.

"
        />
        <WJItem
          icon="https://res.cloudinary.com/unesco-admin/image/upload/v1649704283/book_f8g5dx.png"
          text="Course, conferences exclusive discounts for members

"
        />
        <WJItem
          icon="https://res.cloudinary.com/unesco-admin/image/upload/v1649704028/1456888_ydlu7c.png"
          text="Policy making and advocacy for bioethics and human rights

"
        />
        <WJItem
          icon="https://res.cloudinary.com/unesco-admin/image/upload/v1649704400/document_s0joek.png"
          text="The scholarly publication of International Chair in Bioethics: Global Bioethics
          Enquiry and our Newsletter  
"
        />
        <WJItem
          icon="https://res.cloudinary.com/unesco-admin/image/upload/v1649704027/921305_bmg5j5.png"
          text="Logistical support to conduct local events, Conferences
"
        />
      </ul>
    </div>
    // - exclusive access with curated resource materials on
    // Integrated bioethics.      
  );
};

export default WhyJoin;
