import React from "react";
import "./Vision.scss";

const VisionCompponent = ({ heading, points, flexState, imgLink, para, imgWidth, lastpara,anchor }) => {

  return (
    <div
      className={`vision__container ${!flexState ? 'vision__container__reverse' : ''}`}
    >
      <div className="vision-img-container" >
        <img
          src={imgLink}
          className={`vision-img-container__img ${flexState ? 'vision-img-container__img__largeImg' : ''}`}
          alt=""
        />
      </div>
      <div className="vision__container__text"   >

        <h4 className="vision__container__text__heading">{heading}</h4>
        <div className="vision__container__text__content">
            <p>{para}</p>
          <ul>
            {points.map((point) => (
              <li>{point}</li>
            ))}
          </ul>
          <p>{lastpara}<a href="http://www.icb-ijda.com" target="_blank" rel="noopener noreferrer">{anchor}</a></p>
        </div>
      </div>
    </div>
  );
};

const Vision = (props) => {
  return (
    <div >
      <div ref={props.VisionRef}>
        <VisionCompponent
          heading="Objectives"
          points={[
            "Planning, organizing and implementation of activities confirming to the principles stated in the Universal Declaration on Bioethics and Human Rights adopted by UNESCO's General Conference on 19 October 2005.",
            "Develop ethical compassionate leadership by inculcating the values leadership, management and professionalism in the members of the association.",
            "Develop professional development programmes and resources focussing on academic and hands-on training in the field of bioethics and health law; capacity building for research methodology including publications; leadership and management skills; advocacy, communication and related soft skills.",
            "Develop scientific knowledgebase in the domain of bioethics by conducting ethical research and by dissemination of the findings through publications and other platforms of interest to the IJDA.",
            "Organize the annual IJDA Bioethics, Medicine and Health Law Conference.",
            "Promote and facilitate the participation of IJDA members in conventions and meetings to share their research, and experiences relevant to the association’s objectives.",
          ]}
          flexState
          imgLink='https://res.cloudinary.com/unesco-admin/image/upload/v1672205642/5188822-removebg-preview_gthq1u.png'
          imgWidth='100%'
        />
      </div>
      <div ref={props.ObjectiveRef}>
        <VisionCompponent
          heading="Join us"
          para='IJDA extends its membership to all the medical professionals having age of 35 or less who satisfy at least one of the following criteria:'
          points={[
            "A registered medical professional with license to practice medicine in their country.",
            "A medical resident in training.",
            "Completed bachelors level education or equivalent that is also a minimum qualification required to get the license to practice medicine in their country.",
            "A medical intern who has cleared the qualifying exam for the course that is also a minimum qualification needed to get the license to practice medicine in their country.",
          ]}
          lastpara='Please find more about IJDA and how to join on '
          anchor="www.icb-ijda.com"
          imgLink="https://res.cloudinary.com/unesco-admin/image/upload/v1672205977/80_ZG9jdG9yX2NvbnN1bHRhdGlvbi0wNQ-removebg-preview_ohftrp.png"
          imgWidth='90%'
        />
      </div>
    </div>
  );
};

export default Vision;
