import React from "react";
import "../.././Style/LandingPage/Aboutus.scss";
import ModalImage from "react-modal-image";

function AboutUS() {
  return (
    <div className="AboutUs">
      <h1>About Us:</h1>

      <div className="images">
        <div className="personCard">
          <ModalImage
            small="https://res.cloudinary.com/unesco-admin/image/upload/v1667758298/carmi-2_wcjlpa.jpg"
            large="https://res.cloudinary.com/unesco-admin/image/upload/v1667758298/carmi-2_wcjlpa.jpg"
            alt="image2"
            className="image"
            hideZoom={true}
            hideDownload={true}
          />
          <p className="name">Prof. AMNON CARMI</p>
          <p className="designation">
            HEAD OF INTERNATIONAL CHAIR IN
            BIOETHICS
          </p>
          <p>(ISRAEL)</p>
        </div>

        <div className="personCard">
          <ModalImage
            small="https://res.cloudinary.com/unesco-admin/image/upload/v1643981415/Dr_Russell_DSouza_-_Copy_fabqtz.jpg"
            large="https://res.cloudinary.com/unesco-admin/image/upload/v1643981415/Dr_Russell_DSouza_-_Copy_fabqtz.jpg"
            alt="image1"
            className="image"
            hideZoom={true}
            hideDownload={true}
          />
          <p className="name">Prof. RUSSELL DSOUZA</p>
          <p className="designation">CHAIR<span className="and"> & </span>HEAD OF DEPARTMENT OF EDUCATION HEAD OF THE ASIA PASICIFIC DIVISION
          </p>
          <p>(AUSTRALIA)</p>
        </div>
      </div>

      <div className="images">
        <div className="personCard">
          <ModalImage
            small="https://res.cloudinary.com/unesco-admin/image/upload/v1643981927/Professor_Mary_Mathew_wbgi02.jpg"
            large="https://res.cloudinary.com/unesco-admin/image/upload/v1643981927/Professor_Mary_Mathew_wbgi02.jpg"
            alt="image1"
            className="image"
            hideZoom={true}
            hideDownload={true}
          />
          <p className="name">Prof. Mary Mathew</p>
          <p className="designation">DEPUTY-CHAIR</p>
          <p>(INDIA)</p>
        </div>
      </div>

      <div className="images">

        <div className="personCard">
          <ModalImage
            small="https://res.cloudinary.com/unesco-admin/image/upload/v1643981961/Prof_Gerhard_Fortwengel_e8nrii.png"
            large="https://res.cloudinary.com/unesco-admin/image/upload/v1643981961/Prof_Gerhard_Fortwengel_e8nrii.png"
            alt="image1"
            className="image"
            hideZoom={true}
            hideDownload={true}
          />
          <p className="name">Prof. Gerhard Fortwengel</p>
          <p className="designation">CO-CHAIR</p>
          <p>(GERMANY)</p>
        </div>
        <div className="personCard">
          <ModalImage
            small="https://res.cloudinary.com/unesco-admin/image/upload/v1644002106/Dr_Stacy_Gallin_USA_e9uxsx.jpg"
            large="https://res.cloudinary.com/unesco-admin/image/upload/v1644002106/Dr_Stacy_Gallin_USA_e9uxsx.jpg"
            alt="image1"
            className="image"
            hideZoom={true}
            hideDownload={true}
          />
          <p className="name">Prof. Stacy Gallin</p>
          <p className="designation">CO-CHAIR</p>
          <p>(USA)</p>
        </div>
        <div className="personCard">
          <ModalImage
            small="https://res.cloudinary.com/unesco-admin/image/upload/v1643982008/Dr_Derek_Dsouza_audgyt.jpg"
            large="https://res.cloudinary.com/unesco-admin/image/upload/v1643982008/Dr_Derek_Dsouza_audgyt.jpg"
            alt="image1"
            className="image"
            hideZoom={true}
            hideDownload={true}
          />
          <p className="name">Prof. Derek Dsouza</p>
          <p className="designation">CO-CHAIR</p>
          <p>(INDIA)</p>
        </div>
        <div className="personCard">
          <ModalImage
            small="https://res.cloudinary.com/unesco-admin/image/upload/v1643982033/Prof_Joseph_Thornton_Uni_of_Florida_USA_o5apkn.png"
            large="https://res.cloudinary.com/unesco-admin/image/upload/v1643982033/Prof_Joseph_Thornton_Uni_of_Florida_USA_o5apkn.png"
            alt="image1"
            className="image"
            hideZoom={true}
            hideDownload={true}
          />
          <p className="name">Prof. Joseph Thornton</p>
          <p className="designation">CO-CHAIR</p>
          <p>(USA)</p>
        </div>
      </div>
      <div className="images">

        <div className="personCard">
          <ModalImage
            small="https://res.cloudinary.com/unesco-admin/image/upload/v1644002506/Dr_Lincoln_Ferreira_imrnqo.jpg"
            large="https://res.cloudinary.com/unesco-admin/image/upload/v1644002506/Dr_Lincoln_Ferreira_imrnqo.jpg"
            alt="image1"
            className="image"
            hideZoom={true}
            hideDownload={true}
          />
          <p className="name">Prof. Lincoln Lopez Ferrero</p>
          <p className="designation">CO-CHAIR</p>
          <p>(BRAZIL)</p>
        </div>
        <div className="personCard">
          <ModalImage
            small="https://res.cloudinary.com/unesco-admin/image/upload/v1703666940/tlgvcygdwum1nliqarsf.jpg"
            large="https://res.cloudinary.com/unesco-admin/image/upload/v1703666940/tlgvcygdwum1nliqarsf.jpg"
            alt="image1"
            className="image"
            hideZoom={true}
            hideDownload={true}
          />
          <p className="name">Prof. Vedprakash Mishra</p>
          <p className="designation">CO-CHAIR</p>
          <p>(INDIA)</p>
        </div>
        <div className="personCard">
          <ModalImage
            small="https://res.cloudinary.com/unesco-admin/image/upload/v1644002331/Prof_Daniella_Kediar_rvs2gr.jpg"
            large="https://res.cloudinary.com/unesco-admin/image/upload/v1644002331/Prof_Daniella_Kediar_rvs2gr.jpg"
            alt="image1"
            className="image"
            hideZoom={true}
            hideDownload={true}
          />
          <p className="name">Prof. Daniella Keider</p>
          <p className="designation">CO-CHAIR</p>
          <p>(ISRAEL)</p>
        </div>
      </div>
      <div className="images">
        <div className="personCard">
          <ModalImage
            small="https://res.cloudinary.com/unesco-admin/image/upload/v1672641313/Pearl_ossewn.jpg"
            large="https://res.cloudinary.com/unesco-admin/image/upload/v1672641313/Pearl_ossewn.jpg"
            alt="image1"
            className="image"
            hideZoom={true}
            hideDownload={true}
          />
          <p className="name">Pearl Dsouza</p>
          <p className="designation">ADMINISTRATOR MANAGER</p>
          <p>(INDIA)</p>
        </div>
      </div>

      <div className="content">
        <div className="english"></div>
        <p>
          UNESCO Chair in Biothics (Haifa) was established on 24th July
          2001, with the objective of ensuring the global spread of bioethics
          education. After 20 years in 2021 it was approved as International Chair in Bioethics, University of Porto, Portugal.
          <br />
          <span
            style={{
              textAlign: "right",
              fontSize: "1.2rem",
              color: "#01471B",
              cursor: "pointer",
            }}
            data-toggle="modal"
            data-target="#aboutUsModalLong"
          >
          </span>
        </p>
      </div>
    </div>
  );
}

export default AboutUS;
