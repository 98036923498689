import React from "react";
// import './Comittees.scss'
import "../.././Style/AboutUs/common.scss";
import "../.././Style/AboutUs/Committees.scss";
import MemberData1 from "./MembersData1";
import MemberData2 from "./MembersData2";


const ComitteeMember = ({ name, location, image,designation }) => (
  <div className="member">
    <div
      className="member__image"
      style={{ background: `url(${image}) center center/cover` }}
    ></div>
    <div className="member__name">{name}</div>
    <div className="member__location">{designation}</div>
    <div className="member__location">{location}</div>
  </div>
);

const Research = () => {
  return (
    <>
      <div className="aboutHead">
        <h1>Research Committee</h1>

        <div className="sub-heading">
          <b>International Research Committee </b>
        </div>
        <div className="main-text">
          <p>
            The Department of Education currently is involved in the internationalisation of Bioethics
            educational programs and research initiatives through a global network of bioethics teachers and co-
            learners since its inception. In this transnational approach, we aim to function as a global think- tank
            of consultants and specialists on bioethics and human rights issues. The academic and practical
            applications of bioethics research in various domains of health professions education including
            medical, nursing, dental, pharmacology, physiotherapy and occupational medicine form our area of
            research interest.
          </p>
          <p>
            The International Research Committee at the Department of Education includes a panel of subject
            experts selected from different countries and oversees the research aspects of bioethics and human
            rights education. The members of the international panel give a global perspective as bioethics
            education is interdisciplinary in nature with factors such as multicultural pluralism, belief systems,
            and is governed by international laws and geo-political parameters. Hence expert inputs from
            diverse group of subject experts enable the Department of Education to plan, carry out, and oversee
            the different research initiatives in a realistic and transparent manner applicable to a global
            audience.
          </p>
          <div className="sub-heading">
            <b>Roles of the IRC</b>
          </div>
          <ul>
            <li>
              IRC has the responsibility for execution of the research strategy of the Department of Education.
            </li>
            <li>
              Conducts empirical and conceptual research projects with the help of partner institutions and
              external researchers involved in bioethics and human rights.
            </li>
            <li>
              It ensures the compliance of various research initiatives in accordance with the local, regional,
              national and international regulatory aspects through the partner institutions.
            </li>
            <li>
              Carry out evaluation of the educational programs on its effectiveness and course outcome in
              bioethics education implementation at global contexts.
            </li>
            <li>
              Oversees the research initiatives and makes recommendations on the policy matters relating to
              bioethics research wherein Department of Education is a contributing participant.
            </li>
            <li>
              Acts as a supporting body and mentors for the bioethics co-learners during their research module of
              the advanced PG diploma program in Bioethics education and make any expert opinion or
              recommendations as and when requested by the international educational team.
            </li>
            <li>
              Seek development of international links and collaborative partnerships in bioethics research with
              similar organisations, academia, and social organisations at a global perspective.
            </li>
            <li>
              Ensure the commitment to the research integrity and transmission of knowledge through research
              activities and scientific publications.
            </li>
          </ul>

          <div className="grid-heading">Research Committee</div>
          <div className="member_grid2">
            {MemberData1.map((member) => (
              <ComitteeMember
                name={member.name}
                designation={member.designation}
                location={member.location}
                image={member.image}
              />
            ))}
          </div>
          <div className="grid-heading">Members</div>
          <div className="member_grid">
            {MemberData2.map((member) => (
              <ComitteeMember
                name={member.name}
                designation={member.designation}
                location={member.location}
                image={member.image}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Research;
