import React from "react";

function OurTeam() {
  return (
    <div className="ourTeam">
      <div className="heading">
        <h3>OUR</h3>
        <h3 className="green">TEAM</h3>
      </div>

      <div className="row">
        <div className="col-md-3">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1671879568/Fateh_Ramzy_Fateh_ICCB_-_fatih_remzi_yzeabm.jpg"
            alt=""
          />
          <p className="teamname">Fateh Ramzy Fateh (Iraq)</p>
          <p>President</p>
        </div>
        <div className="col-md-3">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1712731589/Daniel_Wyzynski_pjjue5.jpg"
            alt=""
            style={{ objectFit: "cover", objectPosition: " center top" }}
          />
          <p className="teamname">Daniel Wyzynski</p>
          <p>Secretary</p>
        </div>
        <div className="col-md-3">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1712731589/Ayesha_Ali_usulez.jpg"
            alt=""
            style={{ objectFit: "cover", objectPosition: "top" }}
          />
          <p className="teamname">Ayesha Ali</p>
          <p>VP of External Affairs</p>
        </div>

        <div className="col-md-3">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1712731589/Mariana_Nascimento_Muzzi_utzgi9.jpg"
            alt=""
            style={{ objectFit: "cover", objectPosition: "top" }}
          />
          <p className="teamname">Mariana Nascimento Muzzi</p>
          <p>VP of Education</p>
        </div>

        <div className="col-md-3">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1712731590/Jo%C3%A3o_Vitor_Carmo_de_Novaes_awx62c.jpg"
            alt=""
            style={{ objectFit: "cover", objectPosition: " center top" }}
          />
          <p className="teamname">João Vitor Carmo de Novaes</p>
          <p>VP of Public Relations and Communications</p>
        </div>
      </div>
    </div>
  );
}

export default OurTeam;
