import { React, useEffect, useState } from "react";
import "../Style/Navbar.scss";
import $ from "jquery";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

function Navbar() {
  useEffect(() => {
    $(".nav-link").on("click", () => {
      $(".navbar-toggler").click();

      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
  }, []);

  function onChange(isVisible) {
    !isVisible
      ? $(".navbar").addClass("fixed-top")
      : $(".navbar").removeClass("fixed-top");
  }

  // <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686414213/Logopit_1686414165618_bzzoz9.png" alt="" />

  return (
    <div className="Navbar">
      <VisibilitySensor onChange={onChange} scrollCheck={true}>
        <div
          className="d-flex justify-content-center align-items-center p-2"
          id="header"
        >
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
            alt="logo"
            id="logo"
            onClick={() => {
              window.location.assign("/");
            }}
          />
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1686730963/Logopit_1686414165618_bzzoz9_o2yiqp.png"
            alt="logo2"
            id="logo2"
            onClick={() => {
              window.location.assign("/global-network");
            }}
          />
          <div className="clgHeading">
            <h2 id="clgName">
              DEPARTMENT OF EDUCATION, INTERNATIONAL CHAIR IN BIOETHICS &amp;
              WMA CO-OPERATING CENTRE UNIVERSITY OF PORTO, MELBOURNE, AUSTRALIA
            </h2>
            <h2 id="clgName">
              GLOBAL NETWORK OF BIOETHICS, MEDICAL AND HEALTH PROFESSIONS
              EDUCATION, MELBOURNE, AUSTRALIA
            </h2>
            <h2 id="clgName">FORMERLY UNESCO CHAIR IN BIOETHICS, HAIFA </h2>
          </div>
        </div>
      </VisibilitySensor>

      {/* Navbar  */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav m-auto">
            <li className="nav-item" id="home">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>

            <Link className="nav-link" to="/global-network">
              GNBMHPE
            </Link>

            <Link className="nav-link" to="/about">
              About us
            </Link>

            <Link className="nav-link" to="/courses">
              Courses
            </Link>

            <Link className="nav-link" to="/news">
              News<span className="and"> & </span>Events
            </Link>

            <Link className="nav-link" to="/cpd">
              CPD Session
            </Link>

            <Link className="nav-link" to="/faculty">
              Faculty
            </Link>

            <div className="nav-link drop">
              <Link to="/com">
                Council<span className="and"> & </span>Committee
              </Link>
              <div className={`drop-down`}>
                <Link to="/research" className="drop-down-item">
                  Research Committee
                </Link>
              </div>
            </div>

            <Link className="nav-link" to="/publication">
              Publications
            </Link>

            <Link className="nav-link" to="/pg">
              Gallery
            </Link>

            <Link className="nav-link" to="/ijda">
              IJDA
            </Link>

            <Link className="nav-link" to="/ucbsa">
              ICBSA
            </Link>

            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
