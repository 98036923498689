import "./App.scss";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import jQuery from "jquery";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import Publication from "./components/Publications/Publication";
import ContactUs from "./components/ContactUs";
import PageNotFound from "./components/PageNotFound";
import PrincipalMsg from "./components/AboutUs/PrincipalMsg";
import Faculty from "./components/AboutUs/Faculty";
import About from "./components/AboutUs/About";
import UCBSA from "./components/UCBSA/UCBSA";
import GN from "./components/GN/GN";
import Committees from "./components/AboutUs/Committees";
import UG from "./components/CoursesAndRecognition/UG";
import Galary from "./components/Activities/Galary";
import Webinar from "../src/components/LandingPage/Webinar"
import NewsAndEvents from "./components/News&Events/NewsAndEvents";
import "./Style/Media.scss"
import Research from './components/ResearchAndComittie/Research'
import StudentUnits from "./components/StudentUnits";
import { useEffect } from "react";
import IJDA from "./components/IGDA/IJDA";
import Cpd from "./components/Cpd/Cpd";


function App() {

  jQuery.noConflict();
  useEffect(() => {
    window.$('#ytModal').modal('show');
  }, [])

  return (

    <Router>
      <div className="App">
        <Navbar />
        <div id="ytModal" className="modal fade">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Chair's Message</h4>
                <button type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true">×</button>
              </div>
              <div className="modal-body">
                <iframe id="Geeks3" className="responsive-iframe"
                  src=
                  "https://res.cloudinary.com/unesco-admin/video/upload/v1652625782/Welcome_message_by_the_Chair_Prof_Russell_DSouza_wrn0sh.mp4" title="modalYT"
                  frameBorder="0" allowFullScreen >
                </iframe>
              </div>
            </div>
          </div>
        </div>

        <Switch>
          {/* Landing Page  */}
          <Route exact path="/">
            <LandingPage />
          </Route>

          <Route exact path="/webinars">
            <Webinar />
          </Route>

          <Route exact path="/global-network">
            <GN />
          </Route>


          {/* News  */}
          <Route exact path="/news">
            <NewsAndEvents />
          </Route>

          {/* Departments  */}
          <Route exact path="/courses">
            <UG />
          </Route>

          {/* Infrastructure  */}
          <Route exact path="/publication">
            <Publication />
          </Route>

          <Route exact path="/research">
            <Research />
          </Route>

          <Route exact path="/cpd">
            <Cpd />
          </Route>

          {/* Activities  */}
          <Route exact path="/pg">
            <Galary />
          </Route>

          <Route exact path="/com">
            <Committees />
          </Route>

          {/* About Us  */}
          <Route exact path="/pm">
            <PrincipalMsg />
          </Route>

          <Route exact path="/faculty">
            <Faculty />
          </Route>

          <Route exact path="/about">
            <About />
          </Route>

          <Route exact path="/ijda">
            <IJDA />
          </Route>

          <Route exact path="/icbsa">
            <UCBSA />
          </Route>

          <Route exact path="/stuUnits">
            <StudentUnits />
          </Route>

          {/* Contact Us  */}
          <Route exact path="/contact">
            <ContactUs />
          </Route>

          {/* 404 Page Not Found  */}
          <Route exact path="*">
            <PageNotFound />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
