import React from "react";
import "../.././Style/AboutUs/gn.scss";
import OurTeam from "./OurTeam";
import ReginolDirector from "./ReginolDirector";
import Vision from "./Vision/Vision";
import WhyJoin from "./whyjoin/WhyJoin";
import Objectives from "./Objectives/Vision"
import ContactUs from "./ContactUs/ContactUs";
import {Link} from 'react-router-dom'
import ContactGN from './ContactGn/ContactUs'
function UCBSA() {
  return (
    <div className="gnamain">
      <div className="gnatop">

        <div className="gnleft">
          <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686730963/Logopit_1686414165618_bzzoz9_o2yiqp.png" alt="" />
        </div>
          <h3>GLOBAL NETWORK OF BIOETHICS, MEDICAL AND HEALTH PROFESSIONS EDUCATION, MELBOURNE, AUSTRALIA</h3>
      </div>

      <div className="gnabottom">

        <div className="bottomcontent">
          <p>
          The Global Network of Bioethics, Medical and Health Professions Education (GNBMHPE) was inaugurated on the 27th of December 2022 by Professor Ricardo Leon Borquez, current President of World Federation of Medical Education, in the Presence of Professor Ronald Harden, CEO AMEE and Editor of Medical Teacher the Chief Guest of the function, along with Dr William Pinsky President ECFMG USA and Professor David Gordon Past President WFME. The Global Network of Bioethics, Medical and Health Professions Education is an international network of professionals and institutions dedicated to promoting and advancing Bioethics and Medical and Health Professions Education with the integration of ethical principles in medical and health professions education. 
          </p>
        </div>

        {/* <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686414213/Logopit_1686414165618_bzzoz9.png" alt="" /> */}
      </div>
      
      <OurTeam/>
      <Vision/>
      <ContactUs/>
      <WhyJoin/>
      <Objectives/>
      <ContactGN/>
    </div>
  );
}

export default UCBSA;
