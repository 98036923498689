import React from "react";
import AboutSubComponent from "./AboutSubComponent";

const About = () => {
  return (
    <>
      <div className="about">
        <h1 className="main-heading">About Us</h1>
        <AboutSubComponent
          title="Objectives of the Department of Education"
          content="The Department of Education aims to ensure the global spread of bioethics education. This may be achieved by continuous support and facilitation of the various bioethics units to pursue enhanced and effective local and international collaboration with intensified professional relations with academic institutions and other partners. This will result in continual and direct access to bioethics education, systematic inquiry and analysis with the larger aim of making it possible for participants to put bioethics theory into practice. The Department will oversee and assist bioethics units design, plan and implement the UNESCO Core Curriculum in Bioethics that will enable healthcare practitioners and students to advance their capacity for decision making on ethical issues and human rights considerations of medicine, health care and life sciences. This is expected to translate them into better and more efficient healthcare service providers."
          img="https://res.cloudinary.com/unesco-admin/image/upload/v1645949215/240_F_229481259_BeAVxH1HUwdWQmZIbpzPGjsJauqRgsQh_lticis.jpg"
        />
      </div>

      <div className="about reverse">
        <AboutSubComponent
          title="Vision statement of the Department of Education"
          content="To disseminate and ensure evidence-based bioethics education using the teaching learning methodology which is to be integrated in medical and health science education internationally."
          img="https://res.cloudinary.com/unesco-admin/image/upload/v1646064688/vision_ylzkvr.jpg"
        />
      </div>

      <div className="about">
        <AboutSubComponent
          title="Aims and Objectives:"
          content={
            <ol>
              {
                <li>
                  To promote the integration of bioethics principles based on
                  the UNESCO's Universal Declaration of Bioethics and Human
                  rights.
                </li>
              }
              {
                <li>
                  Implement the vertically and horizontally integrated Bioethics
                  curriculum in medical and health sciences.
                </li>
              }
              {
                <li>
                  Achieving better healthcare outcomes in the community by
                  integrating bioethics teaching training into medical and
                  health science education.
                </li>
              }
              {
                <li>
                  Promote high quality research in the field of bioethics.
                </li>
              }
            </ol>
          }
          img="https://storage.googleapis.com/profit-prod/wp-content/uploads/2021/02/73cb03cc-management-by-objective.jpg"
        />
      </div>
    </>
  );
};

export default About;
