export default [
    {
        name: 'Prof. Russell DSouza',
        location: 'Melbourne, Australia',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981415/Dr_Russell_DSouza_-_Copy_fabqtz.jpg'
    },
    {
        name: 'Prof. Mary Mathew',
        location: 'Manipal, India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981927/Professor_Mary_Mathew_wbgi02.jpg'
    },
    {
        name: 'Prof. Gerhard Fortwengel',
        location: 'Hannover, Germany',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981961/Prof_Gerhard_Fortwengel_e8nrii.png'
    },
    {
        name: 'Prof. William Pinsky',
        location: 'USA',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644003655/William_Pinsky_mlzlb7.jpg'
    },
    {
        name: 'Baroness Prof Ilora Finlay',
        location: 'Cardiff, United Kingdom',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644001918/Baroness_Finlay_2018_002_jqznhp.jpg'
    },
    {
        name: 'Prof. Derek DSouza',
        location: 'Pune, India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1643982008/Dr_Derek_Dsouza_audgyt.jpg'
    },
    {
        name: 'Prof. Joseph Thornton',
        location: 'Florida, USA',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1643982033/Prof_Joseph_Thornton_Uni_of_Florida_USA_o5apkn.png'
    },
    {
        name: 'Prof. Stacy Gallin',
        location: 'New Jersey USA',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002106/Dr_Stacy_Gallin_USA_e9uxsx.jpg'
    },
    {
        name: 'Prof. Vivienne Harpwood',
        location: 'United Kingdom',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002200/Vivienne_Harpwood_-_Chair_qxtw1m.jpg'
    },
    {
        name: 'Prof. Princy Palatty',
        location: 'Kochi, India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002274/princy-louis-palatty_-_Copy_cxwhsd.jpg'
    },
    {
        name: 'Prof. Daniella Keidar',
        location: 'Haifa, Israel',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1645983872/Prof_Daniella_Kediar_rvs2gr.jpg'
    }, {
        name: 'Prof. Shabbir Amanullah',
        location: 'Ontario, Canada',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002427/Prof_Shabbir_Amanullah_London_Canada_yfsew0.jpg'
    }, {
        name: 'Prof. Lincoln Lopez Ferrero',
        location: 'Brazil',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644002506/Dr_Lincoln_Ferreira_imrnqo.jpg'
    },

    {
        name: 'Prof. Ravi Wankhedkar',
        location: 'New Delhi, India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1644004556/Dr_Ravi_Wanhhedkar_kqpes3.jpg'
    },
    {
        name: 'Prof. Praveen Arora',
        location: 'India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1652623909/Praveen_Arora_gyn4lg.png'
    },
    {
        name: 'Prof. Hon Kam Lun Ellis',
        location: 'Hong Kong, China',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1675682470/Hon_photo_IMG_20190723_104949_fhalm0.jpg'
    },

    // {
    //     name : 'Prof. Rounak Verma',
    //     location : 'India',
    //     image : 'https://res.cloudinary.com/unesco-admin/image/upload/v1644006363/Rounak_ukpp5x.png'
    // },    

    {
        name: 'Dr. Shiv Joshi',
        location: 'India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1652623657/shiv_joshi_koedb4.jpg'
    },
    {
        name: 'Dr. Shubham Gupta',
        location: 'India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1652623664/shubham_gupta_srsfc3.jpg'
    },

]