import React, { useState, useEffect } from "react";
import "../.././Style/AboutUs/AboutUs.scss";
import "../.././Style/Admission/Admission.scss";
import axios from "axios";
import Marquee from "react-fast-marquee";
import LoadingScreen from "react-loading-screen";

function Admission() {
  const [events, setEvents] = useState([]);
  const [notice, setNotice] = useState([]);
  const [mous, setMous] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAnnouncements = async () => {
    await axios.get("https://unesco-backend.vercel.app/news").then((res) => {
      const data = res.data;
      setAnnouncements(data);
    });
  };

  const getNotice = async () => {
    await axios.get("https://unesco-backend.vercel.app/notice").then((res) => {
      const data = res.data;
      setNotice(data);
    });
  };

  const getEvents = async () => {
    setLoading(true);
    await axios
      .get("https://unesco-backend.vercel.app/upcomingevent")
      .then((res) => {
        const data = res.data;
        setEvents(data);
      });
    setLoading(false);
  };

  const getMous = async () => {
    setLoading(true);
    await axios.get("https://unesco-backend.vercel.app/mou").then((res) => {
      const data = res.data;
      setMous(data);
    });
    setLoading(false);
  };

  function fetchData() {
    getNotice();
    getEvents();
    getMous();
    getAnnouncements();
  }

  useEffect(() => fetchData(), []);

  return (
    <div id="Admission" className="aboutHead">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Loading..."
      ></LoadingScreen>

      <div className="acontent">
        <div className="marquee-sibling">News</div>
        <Marquee gradient={false} pauseOnHover={true}>
          {announcements.length > 0
            ? announcements.map((value, index) => {
                return <li key={index}>{value.newshead}</li>;
              })
            : null}
        </Marquee>
      </div>

      <div>
        <h1>News</h1>
        {notice.length > 0
          ? notice.map((value, index) => {
              return index % 2 === 0 ? (
                <div className="adm-box">
                  <div className="procedure">
                    <h4>{value.noticeHead}</h4>
                  </div>
                  <div className="procedure-con">
                    <p>{value.noticeDes}</p>
                  </div>
                </div>
              ) : (
                <div className="adm-box2">
                  <div className="procedure-con">
                    <p>{value.noticeDes}</p>
                  </div>
                  <div className="procedure2">
                    <h4>{value.noticeHead}</h4>
                  </div>
                </div>
              );
            })
          : null}

        <h1>Events</h1>
        {events.length > 0
          ? events.map((value, index) => {
              return index % 2 === 0 ? (
                <div className="adm-box">
                  <div className="procedure">
                    <h4>{value.upeventsHead}</h4>
                  </div>
                  <div className="procedure-con">
                    <p>{value.upeventsDes}</p>
                  </div>
                </div>
              ) : (
                <div className="adm-box2">
                  <div className="procedure-con">
                    <p>{value.upeventsDes}</p>
                  </div>
                  <div className="procedure2">
                    <h4>{value.upeventsHead}</h4>
                  </div>
                </div>
              );
            })
          : null}

        <h1>Official MoUs</h1>

        {mous.length > 0
          ? mous.map((value, index) => {
              return (
                <div className="adm-box">
                  <div className="procedure">
                    <h4>{value.head}</h4>
                  </div>
                  <div className="procedure-con">
                    <p>{value.description}</p>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default Admission;
