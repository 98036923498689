import React from 'react'

function ReginolDirector() {
  return (
    <div className="ourTeam">
        <div className="heading" style={{display : 'flex', justifyContent : 'flex-end', paddingRight : '3rem', marginBottom : '1rem'}}>
        <h3>REGIONAL</h3>
        <h3 className="green">DIRECTORS</h3>
      </div>

      <div className="row">
      <div className="col-md-4">
              <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1649243657/Adaeze_Ozoagu_Passport_-_Adaeze_Ozoagu_fnryhv.jpg" alt="" />
              <p className="teamname"> Adaeze Sandra Ozoagu (Nigeria)</p>
              <p >Africa</p>
          </div>

          <div className="col-md-4">
              <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1649243990/Puang_Hafsari_Akbar_-_Puang_Akbar_jbb9pr.jpg" alt="" />
              <p className="teamname">Puang Hafsari Akbar (Indonesia)</p>
              <p >Asia Pacific</p>
          </div>
          
          <div className="col-md-4">
              <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1649243991/Aleksandra_m3vpwg.jpg" alt="" />
              <p className="teamname">Aleksandra Matysiuk (Poland)</p>
              <p >Europe</p>
          </div>

          <div className="col-md-4">
              <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1649243991/Ahmed_Zayed-pic_-_ahmed_tarek_zayed_ftrwlb.jpg" alt="" />
              <p className="teamname">Ahmed Zayed (Egypt)</p>
              <p >Eastern Mediterranean</p>
          </div>

          <div className="col-md-4">
              <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1649243656/Chris_t9veya.png" alt="" />
              <p className="teamname">Chris Zajner (Canada)</p>
              <p >North America</p>
          </div>
          
          <div className="col-md-4">
              <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1649243993/Andrea_Sof%C3%ADa_Dulcey_A._-_Sof%C3%ADa_Dulcey_kfkqqa.jpg" alt="" />
              <p className="teamname">Andrea Sofia Dulcey (Colombia)</p>
              <p >Latin America</p>
          </div>
      </div>
    </div>
  )
}

export default ReginolDirector