import React from "react";

function ReginolDirector() {
  return (
    <div className="ourTeam">
      <div
        className="heading"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "3rem",
          marginBottom: "1rem",
        }}
      >
        <h3>REGIONAL</h3>
        <h3 className="green">DIRECTORS</h3>
      </div>

      <div className="row">
        <div className="col-md-4">
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1712731589/Kelvin_Thiga_Muriuki_i3scnp.jpg"
            alt=""
            style={{ objectFit: "cover", objectPosition: " center top" }}
          />
          <p className="teamname"> Kelvin Thiga Muriuki</p>
          <p>Africa</p>
        </div>
      </div>
    </div>
  );
}

export default ReginolDirector;
