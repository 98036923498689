export default [
    {
        name: 'Prof. Gerhard Fortwengel',
        location: 'Hannover, Germany',
        designation: 'Chair',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1643981961/Prof_Gerhard_Fortwengel_e8nrii.png'
    },
    {
        name: 'Prof. Srinath Baliga',
        designation: 'Co-Chair',
        location: 'India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1652548278/Dr.Srinath_Baliga_sjnncf.jpg'
    },
    {
        name: 'Prof. Lal Madathil ',
        designation: 'Secretary',
        location: 'India',
        image: 'https://res.cloudinary.com/unesco-admin/image/upload/v1652548518/Dr.Lal_Madathil_tjjzxp.jpg'
    },
]