import React, { useState } from "react";
import "./ContactUs.scss";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";

const DropDown = ({ label, item, name, changeItem, options }) => (
  <>
    <Box sx={{ minWidth: "100%" }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          sx={{ height: "50px" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item}
          label={label}
          margin="dense"
          name={name}
          onChange={changeItem}
        >
          {options?.map((option) => (
            <MenuItem value={option.value}>{option.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  </>
);

function ContactUs(props) {
  const [message, setMessage] = useState("Connect With Us");

  const [user, setUser] = useState({
    name: "",
    email: "",
    message: "",
    designation: "",
    country: "",
    phone: "",
    role: "",
    interest: "",
  });

  const [checked, setChecked] = useState(false);

  const toggleCheck = () => {
    checked ? setChecked(false) : setChecked(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const register = () => {
    const {
      name,
      email,
      message,
      role,
      interest,
      phone,
      designation,
      country,
    } = user;
    setMessage("Connecting");
    if (
      name &&
      email &&
      message &&
      role &&
      interest &&
      phone &&
      designation &&
      country
    ) {
      console.log(user);
      axios.post("https://unesco-backend.vercel.app/ijda", user).then((res) => {
        console.log(res);
        setMessage("Message Recieved");
        setTimeout(() => {
          setMessage("Connect With Us");
        }, 4000);
      });
    } else {
      alert("Fill all The Informations");
    }

    setUser({
      name: "",
      email: "",
      designation: "",
      country: "",
      phone: "",
      message: "",
    });
  };
  return (
    <div id="UCBSA-contact" ref={props.ContactRef}>
      <h1>Contact Us</h1>
      <div className="row">
        <div className="col-lg-6 contact-img">
          <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1649701457/2937927-removebg-preview_ikl3m5.png"></img>
          <p></p>
        </div>
        <div className="col-lg-6 ">
          <div className="item2">
            <form className="cntform">
              {/* <span>Contact Us</span> */}
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                name="name"
                onChange={handleChange}
                size="small"
                placeholder="Full Name"
                value={user.name}
                margin="dense"
              />
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                onChange={handleChange}
                size="small"
                placeholder="Email Address"
                margin="dense"
                value={user.email}
              />

              <TextField
                fullWidth
                id="outlined-basic"
                label="Designation"
                variant="outlined"
                name="designation"
                onChange={handleChange}
                size="small"
                placeholder="Designation"
                margin="dense"
                value={user.designation}
              />

              <TextField
                fullWidth
                id="outlined-basic"
                label="Country"
                variant="outlined"
                name="country"
                onChange={handleChange}
                size="small"
                placeholder="Country"
                margin="dense"
                value={user.country}
              />

              <TextField
                fullWidth
                id="outlined-basic"
                label="Phone Number"
                variant="outlined"
                name="phone"
                onChange={handleChange}
                size="small"
                placeholder="Phone Number (Prefferably Whatsapp)"
                margin="dense"
                value={user.phone}
              />
              <DropDown
                label={"Category"}
                item={user.role}
                changeItem={handleChange}
                options={[
                  { value: "Teacher", label: "Teacher" },
                  { value: "Student", label: "Student" },
                  {
                    value: "University Representative",
                    label: "University Representative",
                  },
                  { value: "Other", label: "Other" },
                ]}
                name="role"
              />
              <DropDown
                label={"Interested In"}
                item={user.interest}
                changeItem={handleChange}
                options={[
                  { value: "Courses", label: "Courses" },
                  { value: "Research", label: "Research" },
                  { value: "Conferences", label: "Conferences" },
                  { value: "Membership", label: "Memberships" },
                  { value: "Other", label: "Other" },
                ]}
                name="interest"
              />
              <TextField
                fullWidth
                id="outlined-basic"
                label="Your Messsage"
                variant="outlined"
                name="message"
                onChange={handleChange}
                size="small"
                placeholder="Message"
                value={user.message}
                margin="dense"
                multiline
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <button
                  type="button"
                  onClick={register}
                  className="btn-send"
                  style={{
                    margin: 0,
                    background: "#01471B",
                    width: "30%",
                    borderRadius: "20px",
                    padding: "2%",
                    color: "#ffffff",
                    fontSize: "1.2rem",
                  }}
                >
                  {message}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
