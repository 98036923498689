import React from "react";
import './Image.scss'

class ImageCard extends React.Component {

  constructor(props) {
    super(props);

    this.imageRef = React.createRef();
    this.state = {
      spans : 0
    }


  }

  componentDidMount() {
    this.imageRef.current.addEventListener('load', this.setSpans);
  }

  setSpans = () =>{
   const height = this.imageRef.current.clientHeight;
   const spans = Math.ceil(height/10);

   this.setState({
     spans : spans
   })
  }


  render() {
    return (
      <div className="image-container" style = {{gridRowEnd : `span ${this.state.spans}`}}>
        <img
        className="gallary-image"
          ref = {this.imageRef}
          src={this.props.image}
        />
        <div className="hover-text">{this.props.description}</div>
      </div>
    );
  }
}

export default ImageCard
